import React, { useContext, useEffect, useState, useRef } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import chroma from 'chroma-js'
import { ReactComponent as CheckList } from '../../assets/image/checklist.svg'
import { ReactComponent as IA } from '../../assets/image/STARS-IAIIA.svg'
import { ReactComponent as IAConfirm } from '../../assets/image/ia-ok.svg'
import { ReactComponent as Confirm } from '../../assets/image/ok-review.svg'

import { defaultColors } from '../../constant/documentstype'
import useClickOutside from '../../hook/useClickOutside'

const DocumentRatingParts = (props) => {
  const {
    idFileDoc,
    partFormListState,
    resumeTags,
    activeTag,
    configData,
    showForm,
    tagsWithColors,
    setTagsWithColors,
    documentsPartArray,
    handleActiveTag,
    showContractPart,
    validationDocument,
    showClassification,
    handleFormDataToAi,
    handleOpenModalValidatedDocuments,
    showLabelText,
    labelTag,
    handleTagSelected,
    partSelected,
    showListDocuments,
    isLoadingForm,
    generateRandomColor,
    disableValidationButton,
  } = useContext(DocsContext)

  const [numberTagShowForm, setNumberTagShowForm] = useState()
  const [isValidated, setIsValidated] = useState(false)

  //eslint-disable-next-line no-unused-vars
  const [resetTagSelected, setResetTagSelected] = useState(false)
  const [openList, setOpenList] = useState(false)
  const [validatedDocument, setValidatedDocument] = useState({})
  const [validatedFilterDocument, setValidatedFilterDocument] = useState({})
  const [newTagsAdd, setNewTagsAdd] = useState([])
  const [openNewTaggAdd, setOpenNewTaggAdd] = useState(false)
  const [dropdownPosition] = useState('right')
  const AddNewTagListRef = useRef(null)

  useEffect(() => {
    if (!showForm) {
      const documentToValidated = configData?.documents?.find(
        (item) => item.documentId === idFileDoc
      )
      // console.log("validatedFilterdocument", validatedFilterDocument)
      setValidatedFilterDocument(documentToValidated)
    } else {
      if (idFileDoc) {
        // console.log('ta aqui nao?', resumeTags)
        const documentToValidated = partFormListState?.find((item) => {
          // console.log('Verificando item:', item) // Verificando os dados de cada item
          // Verificando se documentIdByNumberTag existe e se contém algum elemento
          return item?.documentIdByNumberTag?.find((doc) => {
            // console.log('Comparando:', doc?.documentsId, 'com', idFileDoc) // Verificando a comparação
            if (doc?.documentsId?.includes(idFileDoc)) {
              // console.log('Objeto encontrado:', doc)
              return doc
            }
            return
          })
        })
        // console.log('Documento pra validar?', documentToValidated)
        setValidatedFilterDocument(documentToValidated)
      }
      //como descubro se esse documento em especifico esta validado?
    }
  }, [
    idFileDoc,
    configData?.documents,
    showForm,
    resumeTags,
    partFormListState,
  ])
  useEffect(() => {
    // console.log('Validated filtered', validatedFilterDocument)
  }, [validatedFilterDocument])

  // useEffect(() => {
  //   if (idFileDoc && configData?.documents && tagsWithColors) {
  //     const documentToTag = configData.documents.find(
  //       (item) => item?.documentId === idFileDoc
  //     )
  //     const documentToTagWithNumberTag = {
  //       ...documentToTag,
  //       numberTag: documentToTag?.numberTag ?? 1,
  //     }

  //     if (documentToTagWithNumberTag?.part) {
  //       const findTagColor = tagsWithColors.find(
  //         (item) =>
  //           item?.part === documentToTagWithNumberTag?.part &&
  //           item?.numberTag === documentToTagWithNumberTag?.numberTag
  //       )
  //       handleTagSelected(findTagColor || documentToTagWithNumberTag)
  //     } else {
  //       handleTagSelected(documentToTagWithNumberTag)
  //     }
  //   }
  // }, [idFileDoc, configData?.documents])

  useEffect(() => {
    if (!configData?.documents || !activeTag) return

    const documentToValidated = configData.documents.find(
      (item) => item?.documentId === idFileDoc
    )

    // Verificação de `activeTag.tagsValidated` e `numberTagShowForm`
    const hasValidated =
      activeTag.tagsValidated?.find(
        (item) => item.numberTag === activeTag.numberTagShowForm
      )?.tagValidated ?? false

    const updateValidatedDocument = {
      ...documentToValidated,
      numberTagShowForm: activeTag.numberTagShowForm,
      hasValidated: hasValidated,
    }
    // console.log('validatedDocument atualizado:', updateValidatedDocument)

    setValidatedDocument(updateValidatedDocument)
  }, [idFileDoc, configData?.documents, activeTag])

  useEffect(() => {
    // console.log(
    //   'Executando useEffect para atualizar tagsWithColors (showForm: false)'
    // )
    if (!showForm) {
      const documentTags = (configData?.documents || []).filter(
        (item) => item.part
      )
      // console.log('documentTags:', documentTags)

      const uniqueTags = new Set()
      const documentTagPart = documentTags.reduce((acc, item) => {
        const uniqueKey = `${item.part}-${item.numberTag ?? 1}`
        if (!uniqueTags.has(uniqueKey)) {
          uniqueTags.add(uniqueKey)
          acc.push({
            part: item.part,
            numberTag: item.numberTag ?? 1,
          })
        }
        return acc
      }, [])
      // console.log('documentTagPart:', documentTagPart)

      const tags = (configData?.tags || []).map((item) => ({
        part: item,
        numberTag: item.numberTag ?? 1,
      }))
      // console.log('tags:', tags)

      const extraTag = {
        part: labelTag?.part || null,
        color: labelTag?.color || '#ccc',
        numberTag: null,
        tagAdd: 'extraTag',
      }
      // console.log('extraTag:', extraTag)

      let allTags = [...documentTagPart, ...tags]
      const isExtraTagPresent = allTags.find(
        (item) =>
          item.part === extraTag.part && item.numberTag === extraTag.numberTag
      )

      if (!isExtraTagPresent && extraTag.part) {
        allTags.push(extraTag)
      }
      // console.log('allTags após verificar extraTag:', allTags)

      const tagUniques = allTags
        .reduce((acc, item) => {
          if (
            !acc.some(
              (existingItem) =>
                existingItem.part === item.part &&
                existingItem.numberTag === item.numberTag
            )
          ) {
            acc.push({
              part: item.part,
              numberTag: item.part === extraTag.part ? null : item.numberTag,
            })
          }
          return acc
        }, [])
        .sort((a, b) => {
          const tagOrder = (a.part || '').localeCompare(b.part || '')
          if (tagOrder === 0) {
            return (a.numberTag ?? 1) - (b.numberTag ?? 1)
          }
          return tagOrder
        })
      // console.log('tagUniques:', tagUniques)

      const transformedTags = tagUniques
        .filter((tag) => tag.part || tag.part !== null)
        .map((tag, index) => {
          const colorIndex = index % defaultColors.length
          const color =
            colorIndex < defaultColors.length
              ? defaultColors[colorIndex]?.color
              : generateRandomColor()

          if (tag.part === extraTag.part) {
            return {
              id: tagsWithColors.length,
              part: tag?.part,
              color,
              numberTag: 1,
              tagAdd: extraTag?.tagAdd,
            }
          }

          return {
            id: tagsWithColors.length,
            part: tag.part,
            color,
            numberTag: tag.numberTag ?? 1,
            tagAdd: tag?.tagAdd,
          }
        })
      // console.log('transformedTags:', transformedTags)

      setTagsWithColors((prevTags) => {
        const mergedTags = [...prevTags]

        transformedTags.forEach((newTag) => {
          const tagExists = prevTags.some(
            (prevTag) =>
              prevTag.part === newTag.part &&
              prevTag.numberTag === newTag.numberTag
          )
          if (!tagExists) {
            mergedTags.push(newTag)
          }
        })

        // console.log('tagsWithColors atualizado:', mergedTags)
        return mergedTags
      })
    }
  }, [configData, generateRandomColor, labelTag, showForm])

  useEffect(() => {
    // console.log(
    //   'Executando useEffect para atualizar tagsWithColors (showForm: true)'
    // )
    if (showForm) {
      // Código similar ao anterior com logs adicionados
      // ...
    }
  }, [configData, generateRandomColor, labelTag, showForm])

  useEffect(() => {
    if (!configData?.documents || !activeTag) return

    const documentToValidated = configData.documents.find(
      (item) => item?.documentId === idFileDoc
    )

    // Verificação de `activeTag.tagsValidated` e `numberTagShowForm`
    const hasValidated =
      activeTag.tagsValidated?.find(
        (item) => item.numberTag === activeTag.numberTagShowForm
      )?.tagValidated ?? false

    const updateValidatedDocument = {
      ...documentToValidated,
      numberTagShowForm: activeTag.numberTagShowForm,
      hasValidated: hasValidated,
    }
    // console.log('validatedDocument atualizado:', updateValidatedDocument)

    setValidatedDocument(updateValidatedDocument)
  }, [idFileDoc, configData?.documents, activeTag])

  useEffect(() => {
    // console.log(
    //   'Executando useEffect para atualizar tagsWithColors (showForm: false)'
    // )
    if (!showForm) {
      const documentTags = (configData?.documents || []).filter(
        (item) => item.part
      )
      // console.log('documentTags:', documentTags)

      const uniqueTags = new Set()
      const documentTagPart = documentTags.reduce((acc, item) => {
        const uniqueKey = `${item.part}-${item.numberTag ?? 1}`
        if (!uniqueTags.has(uniqueKey)) {
          uniqueTags.add(uniqueKey)
          acc.push({
            part: item.part,
            numberTag: item.numberTag ?? 1,
          })
        }
        return acc
      }, [])
      // console.log('documentTagPart:', documentTagPart)

      const tags = (configData?.tags || []).map((item) => ({
        part: item,
        numberTag: item.numberTag ?? 1,
      }))
      // console.log('tags:', tags)

      const extraTag = {
        part: labelTag?.part || null,
        color: labelTag?.color || '#ccc',
        numberTag: null,
        tagAdd: 'extraTag',
      }
      // console.log('extraTag:', extraTag)

      let allTags = [...documentTagPart, ...tags]
      const isExtraTagPresent = allTags.find(
        (item) =>
          item.part === extraTag.part && item.numberTag === extraTag.numberTag
      )

      if (!isExtraTagPresent && extraTag.part) {
        allTags.push(extraTag)
      }
      // console.log('allTags após verificar extraTag:', allTags)

      const tagUniques = allTags
        .reduce((acc, item) => {
          if (
            !acc.some(
              (existingItem) =>
                existingItem.part === item.part &&
                existingItem.numberTag === item.numberTag
            )
          ) {
            acc.push({
              part: item.part,
              numberTag: item.part === extraTag.part ? null : item.numberTag,
            })
          }
          return acc
        }, [])
        .sort((a, b) => {
          const tagOrder = (a.part || '').localeCompare(b.part || '')
          if (tagOrder === 0) {
            return (a.numberTag ?? 1) - (b.numberTag ?? 1)
          }
          return tagOrder
        })
      // console.log('tagUniques:', tagUniques)

      const transformedTags = tagUniques
        .filter((tag) => tag.part || tag.part !== null)
        .map((tag, index) => {
          const colorIndex = index % defaultColors.length
          const color =
            colorIndex < defaultColors.length
              ? defaultColors[colorIndex]?.color
              : generateRandomColor()

          if (tag.part === extraTag.part) {
            return {
              id: tagsWithColors.length,
              part: tag?.part,
              color,
              numberTag: 1,
              tagAdd: extraTag?.tagAdd,
            }
          }

          return {
            id: tagsWithColors.length,
            part: tag.part,
            color,
            numberTag: tag.numberTag ?? 1,
            tagAdd: tag?.tagAdd,
          }
        })
      // console.log('transformedTags:', transformedTags)

      setTagsWithColors((prevTags) => {
        const mergedTags = [...prevTags]

        transformedTags.forEach((newTag) => {
          const tagExists = prevTags.some(
            (prevTag) =>
              prevTag.part === newTag.part &&
              prevTag.numberTag === newTag.numberTag
          )
          if (!tagExists) {
            mergedTags.push(newTag)
          }
        })

        // console.log('tagsWithColors atualizado:', mergedTags)
        return mergedTags
      })
    }
  }, [configData, generateRandomColor, labelTag, showForm])

  useEffect(() => {
    // console.log(
    //   'Executando useEffect para atualizar tagsWithColors (showForm: true)'
    // )
    if (showForm) {
      // Código similar ao anterior com logs adicionados
      // ...
    }
  }, [configData, generateRandomColor, labelTag, showForm])

  // useEffect(() => {
  //   // console.log('Executando useEffect para handleTagSelected')
  //   if (idFileDoc && configData?.documents && tagsWithColors) {
  //     const documentToTag = configData.documents.find(
  //       (item) => item?.documentId === idFileDoc
  //     )
  //     // console.log('documentToTag:', documentToTag)

  //     const tagValidatedToTag = resumeTags?.find(
  //       (item) => item.tag === documentToTag.part
  //     )
  //     // console.log('tagValidatedToTag:', tagValidatedToTag)

  //     const documentToTagWithNumberTag = {
  //       ...documentToTag,
  //       ...(showForm
  //         ? {
  //             tagsValidated:
  //               tagValidatedToTag?.tagsValidated?.map((validation) => ({
  //                 numberTag: validation?.numberTag ?? 1,
  //                 tagValidated: validation?.tagValidated ?? false,
  //               })) || [],
  //           }
  //         : {
  //             numberTag: documentToTag?.numberTag ?? 1,
  //             tagValidated:
  //               tagValidatedToTag?.tagsValidated?.[0]?.tagValidated ?? false,
  //           }),
  //     }
  //     // console.log('documentToTagWithNumberTag:', documentToTagWithNumberTag)

  //     // console.log('documentToTagWithNumberTag', documentToTagWithNumberTag)

  //     if (documentToTagWithNumberTag?.part) {
  //       const findTagColor = tagsWithColors.find(
  //         (item) =>
  //           item?.part === documentToTagWithNumberTag?.part &&
  //           item?.numberTag === documentToTagWithNumberTag?.numberTag
  //       )
  //       // console.log('findTagColor:', findTagColor)
  //       handleTagSelected(findTagColor || documentToTagWithNumberTag)
  //     } else {
  //       handleTagSelected(documentToTagWithNumberTag)
  //     }
  //   }
  // }, [idFileDoc, configData?.documents, resumeTags, showForm])

  useEffect(() => {
    // console.log("Executando useEffect para handleTagSelected");
    // console.log("ID do documento atual:", idFileDoc);
    // console.log("ConfigData.documents:", configData?.documents);
    // console.log("TagsWithColors:", tagsWithColors);

    if (idFileDoc && configData?.documents && tagsWithColors) {
      // Verifica o documento correspondente ao idFileDoc
      const documentToTag = configData.documents.find(
        (item) => item?.documentId === idFileDoc
      )
      // console.log("Documento correspondente (documentToTag):", documentToTag);

      if (!documentToTag) {
        console.warn('Nenhum documento encontrado com o ID:', idFileDoc)
        return // Sai do efeito se o documento não for encontrado
      }

      // Verifica a tag validada
      const tagValidatedToTag = resumeTags?.find(
        (item) => item.tag === documentToTag.part
      )
      // console.log("Tag validada correspondente (tagValidatedToTag):", tagValidatedToTag);

      // Cria um novo objeto com as propriedades necessárias
      const documentToTagWithNumberTag = {
        ...documentToTag,
        ...(showForm
          ? {
              tagsValidated:
                tagValidatedToTag?.tagsValidated?.map((validation) => ({
                  numberTag: validation?.numberTag ?? 1,
                  tagValidated: validation?.tagValidated ?? false,
                })) || [],
            }
          : {
              numberTag: documentToTag?.numberTag ?? 1,
              tagValidated:
                tagValidatedToTag?.tagsValidated?.[0]?.tagValidated ?? false,
            }),
      }

      // console.log(
      //   "Documento com NumberTag (documentToTagWithNumberTag):",
      //   documentToTagWithNumberTag
      // );

      if (documentToTagWithNumberTag?.part) {
        // console.log(
        //   "Procurando cor para a tag (documentToTagWithNumberTag.part):",
        //   documentToTagWithNumberTag.part
        // );

        const findTagColor = tagsWithColors.find(
          (item) =>
            item?.part === documentToTagWithNumberTag?.part &&
            item?.numberTag === documentToTagWithNumberTag?.numberTag
        )

        // console.log("Cor encontrada (findTagColor):", findTagColor);

        handleTagSelected(findTagColor || documentToTagWithNumberTag)
      } else {
        // console.warn("Documento não possui 'part':", documentToTagWithNumberTag);
        handleTagSelected(documentToTagWithNumberTag)
      }
    } else {
      // console.warn(
      //   "Condição do useEffect não foi satisfeita:",
      //   {
      //     idFileDoc,
      //     documents: configData?.documents,
      //     tagsWithColors,
      //   }
      // );
    }
  }, [idFileDoc, configData?.documents, resumeTags, showForm])

  const handlePartsContract = (tag, index) => {
    // console.log('Chamando handlePartsContract com:', tag, index)
    if (validatedDocument?.validated) {
      // console.log('Documento validado, ação bloqueada.')
      return
    }
    if (
      partSelected?.index === index &&
      (partSelected?.numberTag === tag.numberTag || tag.tagAdd === 'extratag')
    ) {
      // console.log('Desmarcando tag selecionada.')
      handleTagSelected({})
    } else {
      // console.log('Selecionando nova tag:', { ...tag, index })
      handleTagSelected({ ...tag, index })
    }
  }

  // const handleAddNewTag = () => {
  //   console.log('Chamando handleAddNewTag')
  //   if (validatedDocument?.validated) {
  //     console.log('Documento validado, ação bloqueada.')
  //     return
  //   }

  //   setOpenNewTaggAdd(!openNewTaggAdd) //abre a lista tags
  //   console.log('openNewTaggAdd atualizado para:', !openNewTaggAdd)

  //   if (!openNewTaggAdd) {
  //     const partMap = new Map()

  //     // const documentsWithTagAdd = documentsPartArray.map((tag) => ({
  //     //   ...tag,
  //     //   tagAdd: tag.tagAdd ?? '',
  //     //   numberTag: tag.numberTag ?? 1,
  //     // }))
  //     // console.log('documentsWithTagAdd:', documentsWithTagAdd)//aqui eu incluo o numbertag quando nao tem

  //     const documentsWithTagAdd = configData?.documents?.map((tag) => ({
  //       ...tag,
  //       tagAdd: tag.tagAdd ?? '',
  //       numberTag: tag.numberTag ?? 1,
  //     }))
  //     console.log('documentsWithTagAdd:', documentsWithTagAdd)

  //     documentsWithTagAdd.forEach((tag) => {
  //       if (partMap.has(tag.part)) {
  //         return
  //       }

  //       const existingTags = tagsWithColors?.filter(
  //         (t) => t?.part === tag?.part && t?.numberTag === tag?.numberTag
  //       )
  //       console.log('existingTags:', existingTags)

  //       const newNumberTag = tag.numberTag + 1
  //       console.log('newNumberTag:', newNumberTag)

  //       const colorIndex = tagsWithColors.length % defaultColors.length
  //       const colorTagDefaultColors = defaultColors[colorIndex]

  //       const color =
  //         tagsWithColors.length >= defaultColors.length
  //           ? generateRandomColor()
  //           : colorTagDefaultColors.color
  //       console.log('Nova cor gerada:', color)

  //       partMap.set(tag.part, {
  //         ...tag,
  //         numberTag: newNumberTag,
  //         color: color,
  //       })
  //     })

  //     const newPartContractAdd = Array.from(partMap.values())
  //     console.log('newPartContractAdd:', newPartContractAdd)

  //     const filteredTags = newPartContractAdd.filter(
  //       (tag) =>
  //         tag.part !== 'Validados' &&
  //         tag.part !== 'Não Cadastrados' &&
  //         tag.part !== 'Pendentes' &&
  //         tag.tagAdd !== 'extraTag'
  //     )
  //     // console.log('filteredTags:', filteredTags)

  //     setNewTagsAdd(filteredTags)
  //     // console.log('newTagsAdd atualizado:', filteredTags)
  //   }
  // }

  // const handleAddNewTag = () => {
  //   if(validatedDocument?.validated) {
  //     console.log("Documento já está validado!")
  //     return
  //   }
  //   setOpenNewTaggAdd(!openNewTaggAdd) //abre a lista de new tags

  //   if(!openNewTaggAdd) {
  //     const partMap = new Map()

  //     const documentsWithTagAdd = configData?.documents?.map((tag) => ({
  //       ...tag,
  //       tagAdd: tag.tagAdd ?? "",
  //       numberTag: tag.numberTag ?? 1
  //     }))
  //     .filter((tag) => tag.part && tag.part.trim() !== "")

  //     console.log("documentsWithTagAdd (filtrado):", documentsWithTagAdd);

  //     const partTags = {}

  //     documentsWithTagAdd.forEach((tag) => {
  //       if(!partTags[tag.part]) {
  //         partTags[tag.part] = []
  //       }
  //       partTags[tag.part].push(tag,numberTag)
  //     })

  //     console.log("partTags agrupados:", partTags);

  //     Object.entries(partTags).forEach(([part, tags]) => {
  //       tags.sort((a, b) => a - b)
  //       const maxTag = tags[tags.length - 1]
  //       const missingTags = []
  //       for( let i = 1; i <= maxTag; i++) {
  //         if(!tags.includes(i)) {
  //           missingTags.push(i)
  //         }
  //       }

  //       console.log(`Lacunas para ${part}:`, missingTags);

  //       missingTags.forEach((missingTag) => {
  //         const colorIndex
  //       })
  //     })
  //   }
  // }

  const handleAddNewTag = () => {
    // console.log("Chamando handleAddNewTag");
    if (validatedDocument?.validated) {
      // console.log("Documento validado, ação bloqueada.");
      return
    }

    setOpenNewTaggAdd(!openNewTaggAdd) // Abre a lista de tags
    // console.log("openNewTaggAdd atualizado para:", !openNewTaggAdd);

    if (!openNewTaggAdd) {
      const partMap = new Map()

      // Filtra apenas documentos com 'part' preenchido
      const documentsWithTagAdd = configData?.documents
        ?.map((tag) => ({
          ...tag,
          tagAdd: tag.tagAdd ?? '',
          numberTag: tag.numberTag ?? 1,
        }))
        .filter((tag) => tag.part && tag.part.trim() !== '')

      // console.log("documentsWithTagAdd (filtrado):", documentsWithTagAdd);

      // Agrupa os documentos por 'part' e organiza os 'numberTag' existentes
      const partTags = {}
      documentsWithTagAdd.forEach((tag) => {
        if (!partTags[tag.part]) {
          partTags[tag.part] = []
        }
        partTags[tag.part].push(tag.numberTag)
      })

      // console.log("partTags agrupados:", partTags);

      // Preenche lacunas e adiciona novos 'numberTag'
      Object.entries(partTags).forEach(([part, tags]) => {
        tags.sort((a, b) => a - b) // Ordena os 'numberTag' para facilitar a busca de lacunas
        const maxTag = tags[tags.length - 1]

        // Identifica lacunas
        const missingTags = []
        for (let i = 1; i <= maxTag; i++) {
          if (!tags.includes(i)) {
            missingTags.push(i)
          }
        }

        // console.log(`Lacunas para ${part}:`, missingTags);

        // Adiciona tags para as lacunas
        missingTags.forEach((missingTag) => {
          const colorIndex =
            (tagsWithColors.length + missingTag) % defaultColors.length
          const colorTagDefaultColors = defaultColors[colorIndex]

          const color =
            tagsWithColors.length >= defaultColors.length
              ? generateRandomColor()
              : colorTagDefaultColors.color

          // console.log(`Criando tag para lacuna ${part} ${missingTag}: Cor ${color}`);

          partMap.set(`${part}-${missingTag}`, {
            part,
            numberTag: missingTag,
            color,
          })
        })

        // Adiciona o próximo 'numberTag' após o maior
        const newNumberTag = maxTag + 1
        const colorIndex =
          (tagsWithColors.length + newNumberTag) % defaultColors.length
        const colorTagDefaultColors = defaultColors[colorIndex]

        const color =
          tagsWithColors.length >= defaultColors.length
            ? generateRandomColor()
            : colorTagDefaultColors.color

        // console.log(`Criando nova tag para ${part}: NumberTag ${newNumberTag}, Cor ${color}`);

        partMap.set(`${part}-${newNumberTag}`, {
          part,
          numberTag: newNumberTag,
          color,
        })
      })

      const newPartContractAdd = Array.from(partMap.values())
      // console.log("newPartContractAdd:", newPartContractAdd);

      // Filtra para remover tags indesejadas
      const filteredTags = newPartContractAdd.filter(
        (tag) =>
          tag.part !== 'Validados' &&
          tag.part !== 'Não Cadastrados' &&
          tag.part !== 'Pendentes' &&
          tag.tagAdd !== 'extraTag'
      )

      setNewTagsAdd(filteredTags)
      // console.log("newTagsAdd atualizado:", filteredTags);
    }
  }

  useEffect(() => {
    // console.log(configData?.documents)
  }, [configData])

  const addTagToTagWithColors = (tag) => {
    // console.log('Adicionando nova tag:', tag)
    handleTagSelected(tag)
    setOpenNewTaggAdd(false)
    setNewTagsAdd([])
    setTagsWithColors((prevTags) => {
      const tagExists = prevTags.some(
        (prevTag) =>
          prevTag.part === tag.part && prevTag.numberTag === tag.numberTag
      )

      if (tagExists) {
        // console.log('Tag já existe em tagsWithColors.')
        return prevTags
      } else {
        const updatedTags = [...prevTags, tag]
        // console.log('tagsWithColors atualizado:', updatedTags)
        return updatedTags
      }
    })
  }

  useClickOutside(AddNewTagListRef, () => setOpenNewTaggAdd(false))

  // useEffect(() => {
  //   console.log('documenttolint', dataDocumentToLint)
  // }, [dataDocumentToLint])

  useEffect(() => {
    // console.log('useEffect partFormListState::', partFormListState)
    // console.log(
    //   'validatedfilterdocument?.validated',
    //   validatedfilterdocument?.validated
    // )
    // console.log('isLoadingForm', isLoadingForm)
  }, [partFormListState])

  return (
    <S.SectionRatingParts
      showForm={showForm}
      showLabelText={showLabelText}
      validationDocument={validationDocument}
      showClassification={showClassification}
      openList={openNewTaggAdd}
      showContractPart={showContractPart}
      disableValidationButton={disableValidationButton}
    >
      <S.ClassificationDoctype>
        <S.PartsAdd>
          <S.Content validationDocument={validationDocument}>
            <div>
              <h2>{showLabelText}:</h2>
              {!validationDocument && !showForm ? (
                <S.TagParts color={partSelected?.color} labelTag={labelTag?.part} part={partSelected?.part}>
                  {partSelected.part
                    ? `${partSelected.part} ${
                        partSelected.part === labelTag.part
                          ? ''
                          : partSelected.numberTag ?? 1
                      }`
                    : 'Não cadastrado'}
                </S.TagParts>
              ) : null}
            </div>
            {!showForm && showListDocuments && (
              <S.BtnResumeDocs
                onClick={() => {
                  // console.log('Abrindo modal de documentos validados.')
                  handleOpenModalValidatedDocuments()
                }}
                title={'Resumir Documentos'}
              >
                <CheckList />
              </S.BtnResumeDocs>
            )}
            {showForm && (
              <S.WrapperButtonIa
                activeTag={
                  activeTag?.tagsValidated?.find(
                    (tag) =>
                      tag.numberTag === validatedDocument?.numberTagShowForm
                  )?.tagValidated
                }
              >
                <button
                  data-activetag={
                    activeTag?.tagsValidated?.find(
                      (tag) =>
                        tag.numberTag === validatedDocument?.numberTagShowForm
                    )?.tagValidated
                  }
                  data-isloadingform={isLoadingForm}
                  title={'Preencher com IA'}
                  onClick={() => {
                    const isTagValidated = activeTag?.tagsValidated?.find(
                      (tag) =>
                        tag.numberTag === validatedDocument?.numberTagShowForm
                    )?.tagValidated

                    if (isTagValidated || activeTag?.part === labelTag?.part) {
                      // console.log("A tag já está validada. Ação bloqueada.");
                      return
                    }

                    handleFormDataToAi()
                  }}
                >
                  {isLoadingForm ||
                  activeTag?.tagsValidated?.find(
                    (tag) =>
                      tag.numberTag === validatedDocument?.numberTagShowForm
                  )?.tagValidated ||
                  activeTag?.part === labelTag?.part ? (
                    <IAConfirm />
                  ) : (
                    <IA />
                  )}
                </button>
              </S.WrapperButtonIa>
            )}
          </S.Content>
        </S.PartsAdd>
        {!showForm && (
          <S.PartList validationDocument={validationDocument}>
            {tagsWithColors
              ?.slice()
              .sort((a, b) => (a.part || '').localeCompare(b.part || ''))
              .map((tag, index) => (
                <S.PartListItem
                  validatedDocument={validatedDocument?.validated}
                  key={index}
                  part={tag?.part}
                  labelTag={labelTag?.part}
                  color={tag?.color}
                  numberTagPart={tag?.numberTag}
                  partSelectedPart={partSelected?.part}
                  partSelectedNumberTag={partSelected?.numberTag}
                  // color={
                  //   (tag?.part === labelTag || tag?.part === null || tag?.part === '' || tag?.part === "Doc Negociação")
                  //     ? '#42494E'
                  //     : tag?.part === partSelected?.part &&
                  //       (tag.tagAdd === 'extraTag' ||
                  //         tag.numberTag === partSelected?.numberTag)
                  //     ? chroma.valid(tag?.color)
                  //       ? `${chroma(tag?.color)}`
                  //       : 'transparent'
                  //     : chroma.valid(tag?.color)
                  //     ? `${chroma(tag?.color).alpha(0.2)}`
                  //     : chroma('transparent').alpha(0.2)
                  // }
                  onClick={() => handlePartsContract(tag, index)}
                  isSelected={
                    tag.part === partSelected?.part &&
                    (tag.tagAdd === 'extraTag' ||
                      tag.numberTag === partSelected?.numberTag)
                  }
                >
                  {/* {console.log('tag', tag)} */}
                  {tag.part}
                  {!(tag.tagAdd === 'extraTag' || tag?.part === labelTag?.part) && ` ${tag.numberTag}`}
                </S.PartListItem>
              ))}
            {documentsPartArray.length > 0 && (
              <S.ContentList>
                <S.AddNewTag
                  ref={AddNewTagListRef}
                  documentsPartArray={documentsPartArray}
                  onClick={handleAddNewTag}
                  validatedDocument={validatedDocument?.validated}
                >
                  {openNewTaggAdd ? '-' : '+'}
                  <S.AddNewTagList
                    ref={AddNewTagListRef}
                    dropdownPosition={dropdownPosition}
                    onClick={() => setOpenNewTaggAdd(!openNewTaggAdd)}
                    openNewTaggAdd={openNewTaggAdd}
                    validatedDocument={validatedDocument?.validated}
                  >
                    {newTagsAdd
                      ?.filter((item) => item.tagAdd !== 'extraTag')
                      .slice()
                      .sort((a, b) =>
                        (a?.part || '').localeCompare(b.part || '')
                      )
                      .map((item, index) => (
                        <S.AddNewTagListItem
                          key={index}
                          color={item.color}
                          onClick={() => addTagToTagWithColors(item)}
                          validatedDocument={validatedDocument?.validated}
                        >
                          {item?.part} {item?.numberTag}
                        </S.AddNewTagListItem>
                      ))}
                  </S.AddNewTagList>
                </S.AddNewTag>
              </S.ContentList>
            )}
          </S.PartList>
        )}
        {showForm && (
          // <S.PartFormList>
          //   {partFormListState
          //     ?.filter((item) => item?.part)
          //     ?.sort((a, b) => a.part?.localeCompare(b?.part))
          //     ?.map((item, index) => {
          //       if (item.part === 'Não Cadastrados' && validationDocument) {
          //         return null
          //       }
          //       if (item.part === 'Validados' || item.part === 'Pendentes') {
          //         return null
          //       }
          //       return (
          //         <S.PartFormListItem
          //           validatedfilterdocument={validatedfilterdocument?.validated}
          //           dataValidatedfilterdocument={activeTag?.tagsValidated?.find(tag => tag.numberTag === validatedDocument?.numberTag)?.tagValidated}
          //           key={index}
          //           onClick={() => handleActiveTag(item)}
          //           isActive={
          //             item?.part === activeTag?.part &&
          //             item?.numberTag === activeTag?.numberTag
          //           }
          //         >
          //           {item?.part}{' '}
          //           {item?.numberTag !== null &&
          //             item?.numberTag !== undefined &&
          //             item?.tagAdd !== 'extraTag' &&
          //             ` ${item?.numberTag}`}
          //           <S.Badge
          //             validatedFormItem={item.dataValidated}
          //             isActive={
          //               item?.part === activeTag?.part &&
          //               item?.numberTag === activeTag?.numberTag
          //             }
          //           >
          //             <Confirm />
          //           </S.Badge>
          //         </S.PartFormListItem>
          //       )
          //     })}
          // </S.PartFormList>
          <S.PartFormList>
            {partFormListState
              ?.filter((item) => item?.part)
              ?.sort((a, b) => a.part?.localeCompare(b?.part))
              ?.map((item, index) => {
                if (item.part === 'Não Cadastrados' && validationDocument) {
                  return null
                }
                if (item.part === 'Validados' || item.part === 'Pendentes') {
                  return null
                }

                const sortedNumberTags = item?.documentIdByNumberTag?.sort(
                  (a, b) => a.numberTag - b.numberTag
                )
                // console.log('sortedNumberTags:', item.part, sortedNumberTags)
                // console.log('item.tagsValidated', item.part, item.tagsValidated)
                // console.log('tag', tag)
                return (
                  <>
                    {sortedNumberTags?.map((tag, idx) => {
                      const validation = item.tagsValidated?.find(
                        (validation) => validation.numberTag === tag.numberTag
                      )
                      const isTagValidated = validation
                        ? validation.tagValidated
                        : false

                      // console.log(
                      //   'isTagValidated',
                      //   isTagValidated,
                      //   'for tag',
                      //   tag.numberTag
                      // )

                      return (
                        <S.PartFormListItem
                          dataValidatedFilterDocument={
                            validatedDocument?.hasValidated
                          }
                          labelTag={labelTag?.part}
                          part={item?.part}
                          key={`${item.part}-${tag.numberTag}`}
                          onClick={() => handleActiveTag(item, tag.numberTag)}
                          isActive={
                            item?.part === activeTag?.part &&
                            tag?.numberTag === activeTag?.numberTagShowForm
                          }
                        >
                          {item?.part}{' '}
                          {item?.part !== 'Doc. Negociação' &&
                            `${tag?.numberTag}`}
                          {isTagValidated && (
                            <S.Badge
                              validatedFormItem={isTagValidated}
                              isActive={
                                item?.part === activeTag?.part &&
                                tag?.numberTag === activeTag?.numberTagShowForm
                              }
                            >
                              <Confirm />
                            </S.Badge>
                          )}
                        </S.PartFormListItem>
                      )
                    })}
                  </>
                )
              })}
          </S.PartFormList>
        )}
      </S.ClassificationDoctype>
    </S.SectionRatingParts>
  )
}

export default DocumentRatingParts
