import styled from 'styled-components'
import chroma from 'chroma-js'

export const SectionRatingParts = styled.div`
  width: 100%;
  margin: ${({
    showForm,
    showListDocuments,
    showClassification,
    validationDocument,
    disableValidationButton,
  }) => {
    if (disableValidationButton) return '0.5rem 0 1.5rem'
    if (showForm) return '0 0 0.5rem 0'
    if (showListDocuments) return '0.6rem 0 2.2rem'
    if (!validationDocument) return '0.6rem 0 1.5rem'
    if (!showClassification) return '1rem 0 1.5rem'
    if (validationDocument) return '0.6rem 0 0'
    return '0'
  }};
  /* margin: 1rem 0 2rem; */
  display: ${({ showContractPart, showLabelText }) => {
    if (!showContractPart) return 'none'
    return showLabelText ? 'flex' : 'none'
  }};
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  @media (max-height: 1022px) {
  }
`

export const ClassificationDoctype = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  h2 {
    color: #373737;
    font-size: medium;
    font-weight: bold;
    font-size: 0.9rem;
    font-family: 'Museo500sans';
    letter-spacing: 0cap.5;
    color: #373737;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ validationDocument }) =>
    !validationDocument ? 'space-between' : 'space-between'};
  width: 100%;
  text-transform: capitalize;
  h2 {
    margin: ${({ validationDocument }) =>
      !validationDocument ? '0 0.5rem 0 0' : '0'};
    font-family: 'Museo700sans';
  }
  div {
    display: flex;
    align-items: center;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &::first-letter {
    text-transform: uppercase;
  }
`

export const BtnResumeDocs = styled.button`
  height: 2.3rem;
  width: 2.4rem;
  border-radius: 8px;
  border: 1px solid rgb(204, 204, 204);
  background-color: transparent;
  cursor: pointer;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
  }
  &:active {
    transform: scale(0.98);
  }
`

export const WrapperButtonIa = styled.div`
  button {
    border: none;
    border-radius: 8px;
    background-color: transparent;
    padding: 0.5rem;
    /* cursor: not-allowed; */
    cursor: ${({ activeTag, isLoadingForm }) =>
      activeTag || isLoadingForm ? 'not-allowed' : 'pointer'};
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const PartsAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const PartList = styled.ul`
  display: ${({ validationDocument, showListDocuments }) => {
    return validationDocument ? 'flex' : 'none'
  }};
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0 0;
  font-family: 'Museo500sans';
`

export const PartListItem = styled.li`
  background: rgb(228 228 228 / 67%);
  background-color: ${({
    part,
    labelTag,
    tag,
    partSelectedPart,
    partSelectedNumberTag,
    color,
    numberTagPart,
  }) => {
    // console.log({ part, labelTag, tag, partSelectedPart, color, numberTagPart })
    if (part === labelTag || part === null || part === 'Doc. Negociação') {
      if (part !== partSelectedPart) {
        return chroma.valid('#42494E')
          ? `${chroma('#42494E').alpha(0.2)}`
          : chroma('transparent').alpha(0.2)
      }
      return '#42494E'
    }
    if (
      part === partSelectedPart &&
      (tag?.tagAdd === 'extraTag' || numberTagPart === partSelectedNumberTag)
    ) {
      return chroma.valid(color) ? `${chroma(color)}` : 'transparent'
    }
    return chroma.valid(color)
      ? `${chroma(color).alpha(0.2)}`
      : chroma('transparent').alpha(0.2)
  }};

  /* background-color: ${({ color }) => color}; */
  padding: 0rem 0.8rem;
  height: 2.2rem;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin: 0px 0.5rem 0.5rem 0px;
  cursor: ${({ validatedDocument }) =>
    validatedDocument ? 'not-allowed' : 'pointer'};
  &:active {
    transform: ${({ validatedDocument }) =>
      validatedDocument ? 'none' : 'scale(0.98)'};
  }
  &::first-letter {
    text-transform: uppercase;
  }
  &:hover {
    box-shadow: ${({ validatedDocument }) =>
      validatedDocument ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  }
`

export const AddNewTag = styled.button`
  background-color: ${({ validatedDocument }) =>
    validatedDocument ? '#B0B3B7' : '#a6151c'};
  cursor: ${({ validatedDocument }) =>
    validatedDocument ? 'not-allowed' : 'pointer'};
  padding: 0.5rem 0.8rem;
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin: 0 0.5rem 0.5rem 0;
  color: #fff;
  border: none;
  border-radius: 8px;
  position: relative;
  &:hover {
    box-shadow: ${({ validatedDocument }) =>
      validatedDocument ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.2)'};
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
`

export const TagParts = styled.div`
  font-family: 'Museo500sans';
  color: #fff;
  font-size: 0.9rem;
  background-color: ${({ color, part, labelTag }) => {
    if (part == labelTag || part === null) return '#42494E'
    return color ? color : '#B0B3B7'
  }};
  border: 1px solid
    ${({ color, part, labelTag }) => {
      if (part == labelTag || part === null) return '#42494E'
      return color ? color : '#B0B3B7'
    }};
  padding: 0rem 0.6rem;
  height: 2.2rem;
  border-radius: 8px;
  &::first-letter {
    text-transform: uppercase;
  }
`

export const AddTagParts = styled.button`
  display: ${({ openList }) => (openList ? 'none' : 'flex')};
  background-color: ${({ validatedDocument }) =>
    validatedDocument ? '#B0B3B7' : '#a6151c'};
  color: rgb(255, 255, 255);
  border: none;
  font-size: 1rem;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;

  cursor: ${({ validatedDocument }) =>
    validatedDocument ? 'not-allowed' : 'pointer'};

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    box-shadow: ${({ validatedDocument }) =>
      validatedDocument ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
  }
`

export const ContentList = styled.div`
  position: relative;
`

export const AddNewTagList = styled.ul`
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 150px;
  display: ${({ openNewTaggAdd }) => (openNewTaggAdd ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  z-index: 999;
`

export const AddNewTagListItem = styled.li`
  width: 100%;
  padding: 0.8rem;
  text-align: left;
  font-family: 'Museo500sans';
  border-bottom: 0.5px solid rgb(221, 221, 221);
  font-size: 0.9rem;
  cursor: pointer;
  color: #a6151c;
  &:last-child {
    border-bottom: none;
  }
  &::first-letter {
    text-transform: uppercase;
  }
  &:hover {
    background-color: #a6151c;
    border-radius: 8px;
    color: #fff;
  }
`

export const PartFormList = styled.ul`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0 0rem;
  font-family: 'Museo500sans';
`

export const PartFormListItem = styled.li`
  height: 2.2rem;
  position: relative;
  /* min-width: 100%; */
  background-color: ${({
    isActive,
    dataValidatedFilterDocument,
    part,
    labelTag,
  }) => {
    if (isActive) {
      // console.log('part', part == labelTag)
      // console.log(part == labelTag)
      if (part == labelTag || part === null) return '#42494E'
      return dataValidatedFilterDocument ? '#9ca624' : '#a6151c'
    }
    return 'rgb(200 200 200)'
  }};
  padding: 0 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem 0.5rem 0;
  border-radius: 8px;
  color: #fff;
  transition: background-color 0.2s ease;
`

export const Badge = styled.div`
  display: ${({ validatedFormItem, isActive }) => {
    // console.log("validatedFormItem", validatedFormItem)
    if (isActive) return `none`
    return validatedFormItem ? `flex` : `none`
  }};
  position: absolute;
  right: -5px;
  bottom: 60%;
  svg {
    width: 1rem;
    height: 1rem;
  }
  svg path {
    fill: #9ca624;
  }
`
