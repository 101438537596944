import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import ModalListDocuments from '../modal-list-documents'

const ProgressBar = () => {
  const [totalValidateds, setTotalValidateds] = useState(0)
  const [progressWidth, setProgressWidth] = useState(0)
  const [sizePartFormListState, setSizePartFormListState] = useState(0)
  let { documentListData } = useContext(DocsContext)
  const {
    showForm,
    setAllDocsValidated,
    allDocsValidated,
    validationDocument,
    progressBar,
    labelTag,
    configData,
    partFormListState,
    handleOpenModalValidatedDocuments,
  } = useContext(DocsContext)

  useEffect(() => {
    
    const updatedSizePartFormListState = partFormListState.reduce(
      (acc, item) => {
        // console.log("acc", acc, "item", item, "partFormListState", partFormListState);
        if(item?.part === 'Doc. Negociação' || item?.part === labelTag?.part) {
          // console.log("item aqui dentro", acc);
          return acc
        }
        return acc + (item.tagsValidated?.length || 0)
      },
      0
    );
    // console.log("updatedSizePartFormListState", updatedSizePartFormListState);
    setSizePartFormListState(updatedSizePartFormListState);
  }, [partFormListState, labelTag]);
  

  useEffect(() => {
    if (showForm) {
      // setTotalValidateds(
      //   partFormListState.filter((item) =>
      //     item?.tagsValidated?.some((tag) => tag?.tagValidated === true)
      //   ).length
      const filteredPartFormListState = partFormListState.filter((item) => {
        if ((item.part === 'Doc. Negociação' || !item.part) && showForm) {
          return false 
        }
        if (item.part === labelTag?.part && showForm) {
          return false
        }
        return true
      })

      const sizePartFormListStateTrue = filteredPartFormListState.reduce(
        (acc, item) => {
          const validatedTags =
            item.tagsValidated?.filter((tag) => tag.tagValidated === true) || []
          const validatedTagsCount = validatedTags.length
          // console.log(
          //   'Validated tags count for part:',
          //   item.part,
          //   'is:',
          //   validatedTagsCount
          // )
          return acc + validatedTagsCount
        },
        0
      )
      // console.log('Total validated tags:', sizePartFormListStateTrue)
      setTotalValidateds(sizePartFormListStateTrue)
      setProgressWidth(
        (totalValidateds /
          (sizePartFormListState === 0 ? 1 : sizePartFormListState)) *
          100
      )
    } else {
      setTotalValidateds(
        configData?.documents?.filter((item) => item.validated).length
      )
      setProgressWidth(
        (totalValidateds /
          (documentListData.length === 0 ? 1 : documentListData.length)) *
          100
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showForm,
    partFormListState,
    configData?.documents,
    totalValidateds,
    documentListData,
  ])

  useEffect(() => {
    progressWidth === 100
      ? setAllDocsValidated(true)
      : setAllDocsValidated(false)
  }, [progressWidth, setAllDocsValidated])

  return (
    <S.Section
      showForm={showForm}
      validationDocument={validationDocument}
      showProgrees={progressBar}
    >
      <S.Container>
        <ModalListDocuments />

        <S.DocsCountValidated allDocsValidated={allDocsValidated}>
          <S.SectionProgressBar
            allDocsValidated={allDocsValidated}
            validationDocument={validationDocument}
            showProgrees={progressBar}
          >
            <S.ProgressBar allDocsValidated={allDocsValidated}>
              <S.ProgressFill
                progressWidth={progressWidth}
                allDocsValidated={allDocsValidated}
              />
            </S.ProgressBar>
            <S.ProgressBarData>
              <S.ContentProgressBarData>
                <p>{showForm ? 'Dados' : 'Documentos'} validados</p>
                <p>
                  {totalValidateds}/
                  {showForm ? sizePartFormListState : documentListData.length}
                </p>
              </S.ContentProgressBarData>
            </S.ProgressBarData>
          </S.SectionProgressBar>
          <S.ContentButton>
            <S.Button
              allDocsValidated={allDocsValidated}
              onClick={handleOpenModalValidatedDocuments}
            >
              ENVIAR
            </S.Button>
            {/* <S.ContentMinWidth>
              <FilterByContractingParts
                validatedDocument={validatedDocument}
                tagsWithColors={tagsWithColors}
              />
            </S.ContentMinWidth> */}
          </S.ContentButton>
        </S.DocsCountValidated>
        {/* <S.ContentMaxWidth>
          <FilterByContractingParts
            validatedDocument={validatedDocument}
            tagsWithColors={tagsWithColors}
          />
        </S.ContentMaxWidth> */}
      </S.Container>
    </S.Section>
  )
}

export default ProgressBar
