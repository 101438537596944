import styled from 'styled-components'
import chroma from 'chroma-js'

export const Section = styled.section`
  width: 100%;
  display: ${({
    hasResumeTag,
    activeTag,
    validationDocument,
    showListDocuments,
    showForm,
    labelTag,
  }) => {
    if ((activeTag?.part === 'Doc. Negociação' || null || '') && showForm)
      return 'none'
    // console.log(hasResumeTag, partSelected)
    if (activeTag?.part === labelTag?.part && showForm) return 'none'
    // if (!hasResumeTag && showForm) return 'none'
    if (showForm) return 'flex'
    return validationDocument ? 'flex' : 'none'
  }};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${({ showForm, showContractPart, showLabelText }) => {
    if (showForm) return '0.5rem 0 1.5rem'
    if (showLabelText === '' || undefined) return '2.5rem 0 0'
    return showContractPart ? '1.2rem 0 2rem' : '1.5rem 0 2rem 0'
  }};
`

export const ButtonValidateDoc = styled.button`
  width: 90%;
  display: flex;
  height: 2.5rem;
  margin: 0;
  font-weight: 700;
  background-color: ${({
    showForm,
    dataValidated,
    validatedDocument,
    isLoading,
    startingLoading
  }) => {
    // console.log('isloading styled', startingLoading)
    if (isLoading) {
      return '#B0B3B7'
    }
    if (showForm) {
      // console.log("datavalidated?", dataValidated, showForm)
      return dataValidated ? '#9ca624' : '#a6151c'
    } else {
      return validatedDocument ? '#9ca624' : '#a6151c'
    }
  }};
  color: #fff;
  border: none;
  border-radius: 8px;
  align-items: center;
  justify-content: space-around;
  font-size: 0.8rem;
  letter-spacing: 0.4px;
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  transition: transform 0.4s ease, background-color 0.2s ease-in-out;
  &:active {
    transform: scale(0.98);
  }
  &:hover {
    background-color: ${({
      showForm,
      dataValidated,
      validatedDocument,
      isLoading,
    }) => {
      if (isLoading) {
        return '#B0B3B7'
      }
      if (showForm) {
        return dataValidated
          ? chroma('#9ca624').darken(0.2).hex()
          : chroma('#a6151c').darken(0.2).hex()
      } else {
        return validatedDocument
          ? chroma('#9ca624').darken(0.2).hex()
          : chroma('#a6151c').darken(0.2).hex()
      }
    }};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  @media (min-height: 1500px) {
    /* height: 4.5vh; */
  }
`
