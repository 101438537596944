import { createContext, useEffect, useState, useCallback } from 'react'
import { useFetch } from '../interceptor/fetchInterceptor'
import { defaultColors } from '../constant/documentstype'
import { toast } from 'react-toastify'
import { groupDocuments } from '../utils/utils'
import { getAppInsights } from '../insights/app-insights'

export const DocsContext = createContext()

const { Provider } = DocsContext

export const DocsProvider = (props) => {
  const fetchWithAuth = useFetch()
  const [collection, setCollection] = useState([])
  const fileId = collection[0]
  const [loadingDocumentsData, setLoadingDocumentsData] = useState([{}])
  const [configId, setConfigId] = useState('')
  const [isLoadingForm, setIsLoadingForm] = useState(true)
  const [isFormDataEmpty, setIsFormDataEmpty] = useState(true)
  const [validatedDocument, setValidatedDocument] = useState(false)
  const [combinededDocuments, setCombinededDocuments] = useState([])
  const [dataDocumentToLint, setDataDocumentToLint] = useState([])
  const [idFileDoc, setIdFileDoc] = useState('')
  const [totalValidateDocs, setTotalValidateDocs] = useState(0)
  const [allDocsValidated, setAllDocsValidated] = useState(false)
  const [openModalSendDocs, setOpenModalSendDocs] = useState(false)
  const [typeDocuments, setTypeDocuments] = useState('')
  const [contractPartsSelected, setContractPartsSelected] = useState([])
  const [filteredPartsContract, setFilteredPartsContract] = useState()
  const [filterSelectedColor, setFilterselectedcolor] = useState()
  const [analyzerDataList, setAnalyzerDataList] = useState([])
  const [confirmEditValuesDocLInt, setConfirmEditValuesDocLInt] = useState([])
  const [configData, setConfigData] = useState(null)
  const [documentListData, setDocumentListData] = useState([])
  const [classificationListDocs, setClassificationListDocs] = useState([])
  const [contractPartsDoc, setContractPartsDoc] = useState([])
  const [docSelected, setDocSelected] = useState()
  const [progressBar, setProgressBar] = useState(true)
  const [previewSize, setPreviewSize] = useState([])
  const [boxToken, setBoxToken] = useState('')
  const [boxTokenTemp, setBoxTokenTemp] = useState('')
  const [boxTokenExp, setBoxTokenExp] = useState()
  const [timer, setTimer] = useState(0)
  const [colorsByDocumentId, setColorsByDocumentId] = useState({})
  const [tagsWithColors, setTagsWithColors] = useState([])
  const [documentsPartArray, setDocumentsPartArray] = useState([])
  const [previewBoxVisibility, setPreviewBoxVisibility] = useState()
  const [showClassification, setShowClassification] = useState()
  const [showContractPart, setShowContractPart] = useState()
  const [allDataValidated, setAllDataValidated] = useState([])
  const [validationDocument, setValidationDocument] = useState(false)
  const [disableValidationButton, setDisableValidationButton] = useState(false)
  const [showLabelText, setShowLabelText] = useState()
  const [showLabelTag, setShowLabelTag] = useState(false)
  const [labelTag, setLabelTag] = useState('')
  const [showListDocuments, setShowListDocuments] = useState()
  const [openModalAllLists, setOpenModalAllLists] = useState(false)
  const [showError, setShowError] = useState()
  const [canDownloaded, setCanDownloaded] = useState()
  const [canPrinted, setCanPrinted] = useState()
  const [openDocumentPreview, setOpenDocumentPreview] = useState(false)
  const [errorDocBox, setErrorDocBox] = useState([])
  const [submitDocList, setSubmitDocList] = useState([])
  const [activeTag, setActiveTag] = useState([])
  const [showForm, setShowForm] = useState(undefined)
  const [partSelected, setPartSelected] = useState({})
  const [showFilterButton, setShowFilterButton] = useState()
  const [showLabelCloseButton, setShowLabelCloseButton] = useState()
  const [validatedDocumentsModalOpen, setValidatedDocumentsModalOpen] =
    useState()
  const [tagRequiredFile, setTagRequiredFile] = useState()
  const [startingLoading, setStartingLoading] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState()
  const [showResumeDocToContract, setShowResumeDocToContract] = useState()
  const [resumeTags, setResumeTags] = useState([])
  const [partFormListState, setPartFormListState] = useState([])
  const [loadingId, setLoadingId] = useState([])
  const [loadingIdButton, setLoadingIdButton] = useState([])
  const [loadingShowForm, setLoadingShowForm] = useState()
  const [loadingShowFormButton, setLoadingShowFormButton] = useState()
  const [originalConfigData, setOriginalConfigData] = useState([])

  const appInsights = getAppInsights()

  const handleOpenModalValidatedDocuments = () => {
    setValidatedDocumentsModalOpen(!validatedDocumentsModalOpen)
  }

  useEffect(() => {
    const updatedDocuments = configData?.documents?.map((doc) => {
      const { suggested, ...rest } = doc
      return rest
    })

    setConfigData((prevConfigData) => ({
      ...prevConfigData,
      documents: updatedDocuments,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(window.location.search)
      const configId = queryParams.get('id')
      setConfigId(configId)
      // console.log('Config ID:', configId)

      if (configId) {
        try {
          // console.log('Iniciando requisição para obter configuração...')
          const response = await fetchWithAuth(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/configIds/?id=${configId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )

          if (!response.ok) {
            setShowError('Página não encontrada ou acesso não autorizado.')
            throw new Error(`Erro na resposta: ${response.statusText}`)
          }

          const data = await response.json()
          // console.log('Dados recebidos da configuração:', data)
          const index = tagsWithColors?.length % defaultColors.length

          const tagLabelFromData = {
            id: tagsWithColors?.length,
            color: defaultColors[index].color,
            part: data?.labelTag,
            numberTag: null,
          }
          //"1575070820683","1602139173017"
          const idDocumentBox = data.documents.map((item) => item.documentId)
          // console.log('Documentos recebidos:', idDocumentBox)
          // console.log('documentListData', documentListData)

          setConfigData(data)
          setOriginalConfigData(data?.documents)
          setShowForm(data.showForm)
          setCollection(idDocumentBox)
          setProgressBar(data.showProgressBar)
          setShowContractPart(data.showContractPart)
          setShowClassification(data.showClassification)
          // console.log({data: data.showValidationButton});
          setValidationDocument(data.showValidationButton)
          setDisableValidationButton(data.disableValidationButton)
          setTagRequiredFile(data.tagRequired)
          setShowLabelText(data.showLabelText)
          setBoxToken(data.boxToken)
          setShowListDocuments(data.showListDocuments)
          setBoxTokenExp(data.boxTokenExp)
          setClassificationListDocs(data.classifications)
          setContractPartsDoc(data.tags)
          setPreviewSize([80, 20])
          // console.log("showFilterButton", data.showFilterButton)
          setShowFilterButton(data.showFilterButton)
          // setPreviewSize([data.startPreviewSize, 100 - data.startPreviewSize])
          setDocumentListData(data.documents)
          setPreviewBoxVisibility(data.showPreviewDoc)
          setShowLabelTag(data.showLabelTag)
          setCanDownloaded(data.showCanDownload)
          setCanPrinted(data.showCanPrint)
          setLabelTag(tagLabelFromData)
          setResumeTags(data.resumeTags)
          setShowDeleteButton(data.showDeleteButton)
          setShowResumeDocToContract(data.showResumeDocToContract)
          setShowLabelCloseButton(data.showLabelCloseButton)
          if (idDocumentBox.length > 0 && !data.showForm) {
            setIdFileDoc(idDocumentBox[0])
            // console.log('Documento inicial selecionado:', idDocumentBox[0])

            if (idFileDoc) {
              const selectedItem = analyzerDataList?.find(
                (item) => item?.retorno.idDocBox === idFileDoc
              )
              setDocSelected(selectedItem)
              // console.log('Documento selecionado:', selectedItem)
            }
          }

          setDataDocumentToLint(
            Array.from(
              new Map(
                data.documents.map((item) => {
                  // Defina a chave de mapeamento com `part` e `numberTag` (ou `1` como padrão)
                  const key = `${item.part}-${item.numberTag ?? 1}`

                  // Encontre o item correspondente em `resumeTags` para `part`
                  const resumeTagItem = data?.resumeTags.find(
                    (tag) => tag.tag === item.part
                  )

                  // console.log("Analisando item:", item);
                  // console.log("Resume tag item correspondente::", resumeTagItem);

                  // Inicialize `data` como um objeto vazio
                  let dataContent = {}

                  // Caso `resumeTagItem` e `resumeTagItem.items` existam
                  if (resumeTagItem?.itens) {
                    resumeTagItem.itens.forEach((itemObj) => {
                      // console.log("Analisando item dentro de items:", itemObj);

                      // Verifique se o `itemObj.value` existe e é um array
                      if (itemObj.values) {
                        // console.log('ENTRA AQUI?????', item)
                        // console.log('itemObj', itemObj)
                        // Encontre o valor específico com `numberTag` correspondente
                        const matchingValue = itemObj.values.find(
                          (valueObj) =>
                            parseInt(valueObj.numberTag) ===
                            (item.numberTag ?? 1)
                        )

                        // console.log(
                        //   'Valor correspondente encontrado:',
                        //   matchingValue
                        // )

                        // Se houver `matchingValue`, atualize `dataContent`
                        if (matchingValue) {
                          dataContent = {
                            ...dataContent,
                            [itemObj?.key]: matchingValue?.value,
                          }
                        }
                      }
                    })
                  }

                  return [
                    key,
                    {
                      part: item.part,
                      numberTag: item.numberTag ?? 1,
                      data: dataContent || {},
                    },
                  ]
                })
              ).values()
            )
          )

          startInterval()

          const fetchAnalyzerData = async () => {
            try {
              // console.log("Iniciando fetchAnalyzerData");

              // Inicializa o estado de carregamento para todos os documentos
              const initialLoadingState = data.documents.reduce(
                (acc, { documentId }) => {
                  acc[documentId] = 'loading'
                  return acc
                },
                {}
              )
              setLoadingDocumentsData(initialLoadingState)

              // Mapeia os documentos para criar promessas de fetch
              const fetchPromises = data.documents.map(
                async ({ documentId }) => {
                  try {
                    const response = await fetchWithAuth(
                      `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/documentDetails?idDocBox=${documentId}`,
                      {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      }
                    )

                    if (!response.ok) {
                      throw new Error(
                        `Erro na resposta do analisador (ID: ${documentId}): ${response.statusText}`
                      )
                    }

                    const responseData = await response.json()
                    // console.log(
                    //   'Dados do analisador recebidos para documento',
                    //   documentId,
                    //   ':',
                    //   responseData
                    // );

                    // Verifica se os dados retornados estão vazios
                    const isEmpty =
                      !responseData?.retorno?.dados ||
                      Object.keys(responseData.retorno.dados).length === 0

                    // Atualiza o estado de carregamento conforme o resultado
                    setLoadingDocumentsData((prev) => ({
                      ...prev,
                      [documentId]: isEmpty ? 'vazio' : 'finished',
                    }))

                    return { documentId, value: responseData }
                  } catch (error) {
                    console.error(
                      `Erro ao buscar dados para o documento ${documentId}:`,
                      error
                    )

                    // Atualiza o estado para "error" em caso de falha
                    setLoadingDocumentsData((prev) => ({
                      ...prev,
                      [documentId]: 'error',
                    }))

                    return { documentId, error: error.message }
                  }
                }
              )

              // Aguarda todas as requisições serem resolvidas
              const analyzerDataResults = await Promise.all(fetchPromises)

              // console.log('analyzerDataResults:', analyzerDataResults);

              // Processa os resultados e atualiza o estado com os dados
              const processedResults = analyzerDataResults.map((result) => {
                if (result.error) {
                  return { retorno: { dados: {}, idDocBox: result.documentId } } // Valor padrão para erros
                } else {
                  return result.value
                }
              })

              setAnalyzerDataList(processedResults)
            } catch (error) {
              console.error('Erro geral ao buscar dados do analisador:', error)
            }
          }

          const reducedDocuments = data?.documents.reduce((acc, doc) => {
            const part = doc.part || 'Doc. Negociação'
            const numberTag = doc.numberTag || 1
            const existing = acc.find(
              (item) => item.part === part && item.numberTag === numberTag
            )
            if (!existing) {
              acc.push({
                // part: part,
                // numberTag: numberTag,
                id: `${part}-${numberTag}`,
                value: true,
              })
            }

            return acc
          }, [])

          // console.log(reducedDocuments);
          if (reducedDocuments) {
            // setLoadingId(reducedDocuments)
            const initialLoadingState = reducedDocuments.reduce(
              (acc, { id }) => {
                acc[id] = 'loading'
                return acc
              },
              {}
            )

            setLoadingId(initialLoadingState)
          }

          setLoadingShowForm(reducedDocuments)

          const reducedDocumentsToButton = data?.documents.reduce(
            (acc, doc) => {
              const part = doc.part || 'Doc. Negociação'
              const numberTag = doc.numberTag || 1
              const existing = acc.find(
                (item) => item.part === part && item.numberTag === numberTag
              )
              if (!existing) {
                acc.push({
                  // part: part,
                  // numberTag: numberTag,
                  id: `${part}-${numberTag}`,
                  value: true,
                })
              }

              return acc
            },
            []
          )

          // console.log(reducedDocumentsToButton);
          if (reducedDocumentsToButton) {
            // setLoadingId(reducedDocuments)
            const initialLoadingState = reducedDocumentsToButton.reduce(
              (acc, { id }) => {
                acc[id] = false
                return acc
              },
              {}
            )

            setLoadingIdButton(initialLoadingState)
          }

          setLoadingShowFormButton(reducedDocumentsToButton)
          // Chamar a função
          fetchAnalyzerData()
        } catch (error) {
          console.error('Erro ao buscar dados:', error)
        }
      }
    }

    fetchData()
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [fetchWithAuth])

  // useEffect(() => {
  //   _keepConfigIdUp(configId)
  //   /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  // }, [timer])

  const startInterval = () => {
    // console.log('Iniciando intervalo para atualizar timer')
    setInterval(() => {
      setTimer(new Date().getTime())
      // console.log('Timer atualizado:', new Date().getTime())
    }, 1000 * 60 * 3) // 3 minutos
    // }, 10000); // 10 segundos - debug
  }

  // const _keepConfigIdUp = (configId) => {
  //   const refreshBoxToken =
  //     boxTokenExp == null || boxTokenExp <= new Date().getTime() ? true : false
  //   const body = { refreshBoxToken: refreshBoxToken }
  //   fetchWithAuth(
  //     `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/refreshConfig/?id=${configId}`,
  //     {
  //       method: 'POST',
  //       body: JSON.stringify(body),
  //       headers: { 'Content-Type': 'application/json' },
  //     }
  //   )
  //     .then((data) => {
  //       console.log("DATAAAAAA", data)
  //       return data.json()
  //     })
  //     .then((data) => {
  //       if (refreshBoxToken) {
  //         setBoxTokenTemp(data.boxToken)
  //         setBoxTokenExp(data.boxTokenExp)
  //       }
  //     })
  // }

  const _keepConfigIdUp = useCallback(
    async (configId) => {
      try {
        const refreshBoxToken =
          boxTokenExp == null || boxTokenExp <= new Date().getTime()
            ? true
            : false
        const body = { refreshBoxToken: refreshBoxToken }

        const response = await fetchWithAuth(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/refreshConfig/?id=${configId}`,
          {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
          }
        )

        if (!response.ok) {
          let errorMsg = `Erro ${response.status}: ${response.statusText}`
          try {
            const errorData = await response.json()
            if (errorData.msg) {
              errorMsg = errorData.msg
            }
          } catch (e) {}
          throw new Error(errorMsg)
        }

        const contentType = response.headers.get('content-type')
        let data = null
        if (contentType && contentType.includes('application/json')) {
          data = await response.json()
        }

        if (refreshBoxToken && data) {
          setBoxTokenTemp(data.boxToken)
          setBoxTokenExp(data.boxTokenExp)
        }
      } catch (error) {
        console.error('Erro ao atualizar configId:', error.message || error)
      }
    },
    [boxTokenExp, fetchWithAuth]
  )

  useEffect(() => {
    if (configId) {
      _keepConfigIdUp(configId)
    }
  }, [_keepConfigIdUp, configId, timer])

  const downloadDocument = async (idFileDoc) => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${boxToken}` },
    }
    try {
      const response = await fetch(
        `https://api.box.com/2.0/files/${idFileDoc}?fields=download_url`,
        requestOptions
      )
      const result = await response.json()
      window.location.href = result?.download_url
    } catch (error) {
      // console.log(error)
      toast.error(
        `Falha no download do documento. Por favor, tente novamente.`,
        {
          theme: 'light',
        }
      )
    }
  }

  const checkToken = () => {
    // console.log('checkToken chamado, boxTokenTemp:', boxTokenTemp)
    if (!!boxTokenTemp) {
      setBoxToken(null)
      setTimeout(() => {
        setBoxToken(boxTokenTemp)
        // console.log('Token atualizado para:', boxTokenTemp)
      }, 50)
      setBoxTokenTemp(null)
    }
  }

  useEffect(() => {
    if (!resumeTags || resumeTags.length === 0) {
      // console.log("Aguardando dados de resumeTags...");
      return
    }

    const formTagData = configData?.documents?.reduce((acc, doc) => {
      const numberTag = doc.numberTag || 1
      const isNegotiationPart =
        !doc.part || doc.part === '' || doc.part === 'Doc. Negociação'

      if (isNegotiationPart) {
        // Localiza a tag de "Doc. Negociação" em resumeTags
        let negotiationGroup = acc.find(
          (group) => group.part === 'Doc. Negociação'
        )

        if (!negotiationGroup) {
          negotiationGroup = {
            part: labelTag?.part || 'Doc. Negociação',
            numberTag: numberTag,
            documentIdByNumberTag: [],
            tagsValidated: [],
            documentId: [],
            dataValidated: false,
          }
          acc.push(negotiationGroup)
        }

        // Adiciona os documentos a esse grupo
        const tagGroupIndex = negotiationGroup.documentIdByNumberTag.findIndex(
          (tag) => tag.numberTag === numberTag
        )

        if (tagGroupIndex !== -1) {
          negotiationGroup.documentIdByNumberTag[tagGroupIndex] = {
            ...negotiationGroup.documentIdByNumberTag[tagGroupIndex],
            documentsId: [
              ...negotiationGroup.documentIdByNumberTag[tagGroupIndex]
                .documentsId,
              doc.documentId,
            ],
          }
        } else {
          negotiationGroup.documentIdByNumberTag.push({
            numberTag: numberTag,
            documentsId: [doc.documentId],
          })
        }

        const validatedTagIndex = negotiationGroup.tagsValidated.findIndex(
          (tag) => tag.numberTag === numberTag
        )

        if (validatedTagIndex === -1) {
          negotiationGroup.tagsValidated.push({
            numberTag: numberTag,
            tagValidated: false, // Valor padrão, pode ser ajustado
          })
        }

        negotiationGroup.documentId = [
          ...negotiationGroup.documentId,
          doc.documentId,
        ]
        return acc // Retorne o acumulador diretamente
      }

      const existingGroupIndex = acc.findIndex(
        (group) => group.part === doc.part
      )

      const filterResumeTagByPart = resumeTags.find(
        (item) => item.tag === doc.part
      )

      // console.log("filterResumeTagByPart", filterResumeTagByPart)

      const tagValidated =
        filterResumeTagByPart?.tagsValidated?.find(
          (tag) => tag.numberTag === numberTag
        )?.tagValidated || false

      // console.log("tagValidated:", tagValidated);

      if (existingGroupIndex !== -1) {
        const updatedGroup = { ...acc[existingGroupIndex] }

        updatedGroup.documentId = [...updatedGroup.documentId, doc.documentId]

        const tagGroupIndex = updatedGroup.documentIdByNumberTag.findIndex(
          (tag) => tag.numberTag === numberTag
        )

        if (tagGroupIndex !== -1) {
          updatedGroup.documentIdByNumberTag[tagGroupIndex] = {
            ...updatedGroup.documentIdByNumberTag[tagGroupIndex],
            documentsId: [
              ...updatedGroup.documentIdByNumberTag[tagGroupIndex].documentsId,
              doc.documentId,
            ],
          }
        } else {
          updatedGroup.documentIdByNumberTag.push({
            numberTag: numberTag,
            documentsId: [doc.documentId],
          })
        }

        const validatedTagIndex = updatedGroup.tagsValidated.findIndex(
          (tag) => tag.numberTag === numberTag
        )

        if (validatedTagIndex === -1) {
          updatedGroup.tagsValidated.push({
            numberTag: numberTag,
            tagValidated: tagValidated,
          })
        } else {
          updatedGroup.tagsValidated[validatedTagIndex].tagValidated =
            tagValidated
        }

        acc[existingGroupIndex] = updatedGroup
      } else {
        acc.push({
          part: doc.part,
          numberTag: numberTag,
          documentIdByNumberTag: [
            {
              numberTag: numberTag,
              documentsId: [doc.documentId],
            },
          ],
          tagsValidated: [{ numberTag: numberTag, tagValidated: tagValidated }],
          documentId: [doc.documentId],
          dataValidated: doc.dataValidated || false,
        })
      }

      return acc
    }, [])

    // console.log('FOOOOOOOOORRRRRMMMMMM', formTagData) // Exibe o array final

    if (formTagData && showForm) {
      // console.log('tem gente aqui?')
      setPartFormListState(formTagData)
      const teste = formTagData
        .filter((item) => item.part)
        .sort((a, b) => a?.part.localeCompare(b?.part))

      const firstItems = teste[0]

      // console.log('teste', teste)
      // console.log('firstItems', firstItems)
      // console.log('firstItems documentID', firstItems.documentId)

      if (partFormListState && firstItems) {
        // const firstDocumentId = firstItems.documentIdByNumberTag[0]?.documentsId
        // const firstItemActiveTag = firstItems.documentIdByNumberTag[0]?.numberTag
        // console.log('firstItemActiveTag', firstItemActiveTag)
        // console.log(`chamou aqui?`)

        const sortedByNumberTag = firstItems.documentIdByNumberTag.sort(
          (a, b) => a.numberTag - b.numberTag
        )

        // const firstDocumentId = sortedByNumberTag[0]?.documentsId
        const firstItemActiveTag = sortedByNumberTag[0]?.numberTag

        // console.log('firstItemActiveTag', firstItemActiveTag)
        // console.log('firstDocumentId', firstDocumentId)

        const updatedFirstItems = {
          ...firstItems,
          numberTagShowForm: firstItemActiveTag,
        }
        // console.log('ta vindo true activetag?', updatedFirstItems)
        setActiveTag(updatedFirstItems)
        setCollection(firstItems.documentIdByNumberTag[0]?.documentsId)
        // console.log(firstItems.documentIdByNumberTag[0]?.documentsId)
        setIdFileDoc(firstItems.documentIdByNumberTag[0]?.documentsId[0])
        if (updatedFirstItems) {
          // setLoadingId((prev) => ({
          //   ...prev,
          //   [`${updatedFirstItems.part}-${updatedFirstItems.numberTagShowForm}`]: true,
          // }));
        }
        // setTimeout(() => {
        //   const initialLoadingState = reducedDocuments.reduce((acc, { id }) => {
        //     acc[id] = false;
        //     return acc;
        //   }, {});

        //   setLoadingId(initialLoadingState);
        // }, 5000)

        // setIsLoading(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, resumeTags, showForm])

  useEffect(() => {
    if (!resumeTags || resumeTags.length === 0) {
      // console.log('Aguardando dados de resumeTags...')
      return
    }

    const fetchDataForm = async () => {
      setIsLoadingForm(true)
      // console.log(loadingId)
      if (configData?.documents) {
        const finalDocumentsArray = groupDocuments(
          configData.documents,
          tagsWithColors
        )
        // console.log("finalDocumentsArray após groupDocuments:", finalDocumentsArray);

        const groupedDocuments = finalDocumentsArray.reduce((acc, doc) => {
          const key = `${doc.part}-${doc.numberTag}`
          if (!acc[key]) {
            acc[key] = {
              id: Object.keys(acc).length + 1,
              part: doc.part,
              numberTag: doc.numberTag,
              documents: [],
              tagAdd: doc.tagAdd,
            }
            // console.log(`Novo grupo criado para key=${key}:`, acc[key]);
          }

          doc.documentId?.forEach((id) => {
            const docConfigData = configData.documents.find(
              (item) => item.documentId === id
            )
            const docAnalyzerData = analyzerDataList?.find(
              (item) => item?.retorno && item.retorno.idDocBox === id
            )

            if (docConfigData || docAnalyzerData) {
              acc[key].documents.push({
                documentId: id,
                documentData: {
                  configData: docConfigData || {},
                  analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
                },
              })
              // console.log(`Documento adicionado ao grupo ${key} com id=${id}:`, acc[key]);
            } else {
              // console.log(`Documento com id=${id} não encontrado no configData ou analyzerData.`);
            }
          })

          return acc
        }, {})

        // console.log("groupedDocuments após processamento inicial:", groupedDocuments);

        const otherDocuments = configData.documents.reduce((acc, doc) => {
          const docAnalyzerData = analyzerDataList?.find(
            (item) => item?.retorno && item.retorno.idDocBox === doc.documentId
          )

          acc.push({
            documentId: doc.documentId,
            documentData: {
              configData: doc || {},
              analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
            },
          })
          // console.log("Documento adicionado a otherDocuments:", doc.documentId);

          return acc
        }, [])

        // console.log("otherDocuments final:", otherDocuments);

        if (otherDocuments.length > 0) {
          groupedDocuments['Outros'] = {
            id: Object.keys(groupedDocuments).length + 1,
            part: 'Outros',
            numberTag: null,
            documents: otherDocuments,
          }
          // console.log("Grupo 'Outros' adicionado a groupedDocuments:", groupedDocuments['Outros']);
        }

        const finalArray = Object.values(groupedDocuments)
        // console.log("Array final após conversão de groupedDocuments:", finalArray);

        if (finalArray.length > 0) {
          const newCombinedDocuments = finalArray.map((doc) => {
            // console.log("Processando documento para newCombinedDocuments:", doc);

            const dadosList =
              doc.documents.flatMap((item) => {
                const dados = item.documentData.analyzerData.dados || {}
                const label =
                  item.documentData.configData.classification?.label ||
                  'Não classificado'

                return Object.entries(dados).map(([key, value]) => ({
                  key,
                  label,
                  value: value || 'Não cadastrado',
                }))
              }) || []

            const dadosSeparados = dadosList
              .filter(({ key }) => !/hidden/i.test(key))
              .map(({ key, value, label }) => ({
                key,
                label,
                value,
              }))

            // const numberTagValue = showForm ? doc.numberTagShowForm : doc.numberTag;
            const numberTagValue = doc.numberTag
            // console.log(`Definindo numberTag para part=${doc.part}:`, numberTagValue);

            return {
              part: doc.part,
              numberTag: numberTagValue,
              dados: dadosSeparados,
            }
          })
          // console.log("newCombinedDocuments após mapeamento:", newCombinedDocuments);

          const hasData = newCombinedDocuments.some(
            (item) => item.dados.length > 0
          )
          // console.log("Verificando se newCombinedDocuments possui dados:", hasData);

          if (hasData) {
            setCombinededDocuments(newCombinedDocuments)

            // console.log('AQUIIIIIIII')

            const updateDataTags = async () => {
              if (!resumeTags) return

              // console.log('Iterando sobre resumeTags')

              const promises = resumeTags.map(async (tag) => {
                const templateField = tag.itens
                  .filter((item) => item.key && item.description)
                  .map((item) => `'${item.key}' ${item.description}`)
                  .join(', ')

                // console.log('Tag atual:', tag)
                // console.log('Template Field:', templateField)
                //aqui ta mandando o templatefield para gerar os dados
                //separados por partes corretamente
                // console.log('newCombinedDocuments', newCombinedDocuments)
                // console.log('resumeTags', resumeTags)

                const hasDataIa = newCombinedDocuments.filter(
                  (doc) =>
                    doc.part === tag.tag &&
                    tag.itens?.find((item) =>
                      item.values?.find(
                        (value) => value.numberTag == doc.numberTag
                      )
                    ) == null
                )
                // console.log('hasDataIa atual:', hasDataIa)

                if (hasDataIa.length > 0) {
                  try {
                    const results = await Promise.allSettled(
                      hasDataIa.map(async (doc) => {
                        const payload = {
                          tipoPessoa:
                            doc.part === 'Empresa' ? 'Jurídica' : 'Física',
                          listaCampos: templateField,
                          dadosJson: doc.dados
                            .map((d) => `${d.key}: ${d.value}`)
                            .join(','),
                          retornarGPTPrompt: false,
                        }

                        // console.log(
                        //   'Payload enviado:',
                        //   payload,
                        //   'numberTag:',
                        //   doc.numberTag
                        // )

                        const response = await fetchWithAuth(
                          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/unifyData`,
                          {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(payload),
                          }
                        )

                        const result = await response.json()
                        return { result, numberTag: doc.numberTag }
                      })
                    )

                    results.forEach((res, index) => {
                      // console.log('Resultado:', res)

                      if (res.status === 'fulfilled') {
                        // Procurando se a tag foi validada
                        const hasValidatedTag = resumeTags
                          ?.find((item) => item?.tag === tag.tag)
                          ?.tagsValidated?.find(
                            (item) =>
                              item.numberTag === hasDataIa[index].numberTag
                          )
                        // console.log('hasValidatedTag', hasValidatedTag)

                        // if (hasValidatedTag?.validated === false) {
                        if (res.value) {
                          const retornoDados =
                            res.value.result?.retorno?.dados || {}
                          // console.log('retornoDados', retornoDados)

                          Object.entries(retornoDados).forEach(
                            ([name, value]) => {
                              // console.log('name', name, 'value', value)
                              // console.log('MAHOOOOOOOOOOIIII')

                              setDataDocumentToLint((prevData) => {
                                // console.log('PrevData:', prevData)
                                return prevData.map((item) => {
                                  // console.log('item dentro do ', item)
                                  if (
                                    item.part === tag.tag &&
                                    item.numberTag ===
                                      hasDataIa[index].numberTag
                                  ) {
                                    // console.log('Match encontrado! Atualizando:', item)
                                    return {
                                      ...item,
                                      data: {
                                        ...item.data,
                                        ...(value && !item.data[name]
                                          ? { [name]: value }
                                          : {}),
                                      },
                                    }
                                  }
                                  return item
                                })
                              })
                            }
                          )
                          // console.log("id:", tag.tag, "numberTag:", hasDataIa[index].numberTag)
                          setTimeout(() => {
                            const initialLoadingState = loadingShowForm.reduce(
                              (acc, { id }) => {
                                // console.log("id dentro de initialLoading, cade?????", id)
                                acc[id] = false
                                return acc
                              },
                              {}
                            )

                            setLoadingId(initialLoadingState)
                          }, 2000)
                          // }
                        } else {
                          // console.log(`Tag ${tag.tag} e numberTag ${hasDataIa[index].numberTag} já foram validados.`);
                          setTimeout(() => {
                            const initialLoadingState = loadingShowForm.reduce(
                              (acc, { id }) => {
                                // console.log("id dentro de initialLoading, cade?????", id)
                                acc[id] = false
                                return acc
                              },
                              {}
                            )

                            setLoadingId(initialLoadingState)
                          }, 1000)
                        }
                      } else {
                        console.error(
                          `Erro para tag: ${tag.tag}, numberTag: ${hasDataIa[index].numberTag}`,
                          res.reason
                        )
                      }
                    })
                  } catch (error) {
                    setTimeout(() => {
                      const initialLoadingState = loadingShowForm.reduce(
                        (acc, { id }) => {
                          acc[id] = false
                          return acc
                        },
                        {}
                      )

                      setLoadingId(initialLoadingState)
                    }, 1000)
                    console.error('Erro ao processar dados:', error)
                  }
                }
              })

              await Promise.all(promises)
            }

            updateDataTags()
            // console.log('Bate aqui?')

            // console.log("newCombinedDocuments definido em estado:", newCombinedDocuments);
          } else {
            // console.log("Nenhum dado válido encontrado em newCombinedDocuments.");
          }
          setIsLoadingForm(false)
        }
      } else {
        // console.log("ConfigData não encontrado ou está vazio.");
        setIsLoadingForm(false)
      }
    }

    fetchDataForm()
  }, [configData, tagsWithColors, showForm, analyzerDataList, resumeTags])

  /**
   *
   * aqui acaba os useeffect de requisicao
   */

  useEffect(() => {}, [showForm, partFormListState])

  const handleDocumentsPartArray = (documents) => {
    // console.log('handleDocumentsPartArray chamado com documentos:', documents)
    setDocumentsPartArray(documents)
  }

  const handleTagSelected = (tag) => {
    // console.log('nao ta batendo?', tag)
    setPartSelected(tag)
  }

  useEffect(() => {
    // console.log('resumeTags', resumeTags)
  }, [resumeTags])

  const setDocumentColor = (idFileDoc, document, color) => {
    // console.log('setDocumentColor chamado com:', { idFileDoc, document, color })
    setColorsByDocumentId((prevColors) => ({
      ...prevColors,
      [idFileDoc]: {
        ...(prevColors[idFileDoc] || {}),
        [document]: color,
      },
    }))
  }

  const generateRandomColor = () => {
    // console.log('generateRandomColor chamado')
    const minColorValue = 0 // Valor mínimo para os componentes RGB
    const maxColorValue = 180 // Limita o máximo para evitar tons muito claros
    const randomColor = `rgb(${[0, 1, 2]
      .map(() =>
        Math.floor(
          Math.random() * (maxColorValue - minColorValue) + minColorValue
        )
      )
      .join(', ')})`
    // console.log('Cor gerada:', randomColor)
    return randomColor
  }

  const getDocumentColor = (idFileDoc, document) => {
    return colorsByDocumentId[idFileDoc]?.[document] || generateRandomColor()
  }

  const handleActiveTag = (item, numberTagShowForm) => {
    // console.log(`handleactive, item`, item)
    // console.log(`handleactive, item`, item, { numberTagShowForm })
    // console.log('qual alldatavalidated', allDataValidated)
    if (!showForm) {
      setActiveTag(item)
      // console.log('aqui ta dentro de activetag', item)
      setCollection(item?.documentId)
      setIdFileDoc(item?.documentId[0])
    } else {
      // console.log(`handleactive, item`, item, { numberTagShowForm })
      // console.log('qual alldatavalidated', allDataValidated)
      const updatedItem = {
        ...item,
        numberTagShowForm,
      }
      const getCollection = item.documentIdByNumberTag.find(
        (item) => item.numberTag === numberTagShowForm
      )
      setActiveTag(updatedItem)
      // console.log('aqui ta dentro de activetag', item)
      // console.log('getCollection dentro de handleActiveTag', getCollection)
      setCollection(getCollection?.documentsId)
      setIdFileDoc(getCollection?.documentsId[0])
    }
  }

  const handleUpdateActiveTag = (item) => {
    // console.log(`activetagupdate`, item)
    // console.log("quando bate aqui no handleUpdateActiveTag")
    setActiveTag(item)
  }

  useEffect(() => {
    // console.log(activeTag)
  }, [activeTag])

  const handleQttValidation = () => {
    // console.log('handleQttValidation chamado')
    const validatedCount = configData.documents.filter(
      (doc) => doc.validated === true
    ).length
    // console.log('Quantidade de documentos validados:', validatedCount)
    setTotalValidateDocs(validatedCount)
  }

  const handleSelectedDocument = (item) => {
    // console.log('handleSelectedDocument chamado com o item:', item)

    if (!item) {
      console.warn(
        'Aviso: O item fornecido para handleSelectedDocument está vazio ou indefinido.'
      )
    } else {
      // console.log('Atualizando estado de docSelected com o item:', item)
    }

    setDocSelected(item)
    // console.log('Estado de docSelected atualizado com sucesso.')
  }

  const handleOpenModalSend = () => {
    //  console.log('handleOpenModalSend chamado')
    setOpenModalSendDocs(!openModalSendDocs)
  }
  const handleErrorDocBox = (url) => {
    //  console.log('handleErrorDocBox chamado com URL:', url)
    const regex = /idDocBox=([^&]+)/
    const match = url.match(regex)

    if (match) {
      const idDocBox = match[1]
      //   console.log('ID Doc Box extraído:', idDocBox)

      setErrorDocBox((prevErrors) => {
        if (!prevErrors.includes(idDocBox)) {
          /* console.log(
            'Erro não encontrado anteriormente, adicionando:',
            idDocBox
          ) */
          return [...prevErrors, idDocBox]
        }
        return prevErrors
      })
    }
  }

  const handleTypeDocumentsOption = async (value, id) => {
    let updatedDocument = null
    document.body.style.cursor = 'wait'

    // Inicializa o estado de carregamento para o documento com id específico
    setLoadingDocumentsData((prev) => ({
      ...prev,
      [id]: 'loading', // Define o status de carregamento como 'loading' para o id atual
    }))

    // Atualiza os documentos com a nova classificação
    const updatedDocuments = configData.documents.map((doc) => {
      if (doc.documentId === id) {
        updatedDocument = {
          ...doc,
          classification: value,
        }
        return updatedDocument
      }
      return doc
    })

    let idAnalyzer = null
    analyzerDataList?.forEach((analyzerDoc) => {
      if (analyzerDoc?.retorno.idDocBox === id) {
        idAnalyzer = analyzerDoc?.retorno.idAnalyzer
      }
    })

    const newAnalyzerDataListFiltered = analyzerDataList?.filter(
      (item) => item.retorno.idDocBox !== id
    )

    setAnalyzerDataList(newAnalyzerDataListFiltered)
    console.log(updatedDocument)

    const payload = {
      idParams: configId,
      classificationDocument: updatedDocument,
      idAnalyzer,
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/classifyDocument`,
        requestOptions
      )
      const result = await response.json()

      if (!result?.retorno?.idAnalyzer) {
        let newAnalyzerDataListFiltered = []
        analyzerDataList?.forEach((item) => {
          if (item?.retorno?.idAnalyzer === idAnalyzer) {
            if (item && item.retorno) {
              item.retorno.dados = null
              item.retorno.lista = null
            }
          }
          newAnalyzerDataListFiltered.push(item)
        })

        setAnalyzerDataList(newAnalyzerDataListFiltered)
      } else {
        let newAnalyzerDataListFiltered = analyzerDataList?.filter(
          (item) => item?.retorno?.idAnalyzer !== result?.retorno?.idAnalyzer
        )
        newAnalyzerDataListFiltered.push(result)
        setAnalyzerDataList(newAnalyzerDataListFiltered)
      }

      setConfigData((prevData) => ({
        ...prevData,
        documents: updatedDocuments,
      }))

      setTypeDocuments(value)
      // console.log("ta batendo aqui pelo menos?");

      // Atualiza o estado de loadingDocumentsData baseado nos dados recebidos
      if (
        result?.retorno?.dados &&
        Object.keys(result?.retorno?.dados).length === 0
      ) {
        // console.log('retorno.dados é vazio:', result?.retorno?.dados);
        setLoadingDocumentsData((prev) => ({
          ...prev,
          [id]: 'vazio', // Marca o status como 'vazio' se os dados forem vazios
        }))
      } else {
        // console.log('retorno.dados preenchido:', result.retorno.dados);
        setLoadingDocumentsData((prev) => ({
          ...prev,
          [id]: 'finished', // Marca o status como 'finished' se os dados foram preenchidos
        }))
      }
    } catch (error) {
      console.error('Erro na classificação do documento:', error)
      setLoadingDocumentsData((prev) => ({
        ...prev,
        [id]: 'error', // Marca o status como 'error' se ocorrer um erro
      }))
      toast.error(`Erro na classificação do documento`, {
        theme: 'light',
      })
    } finally {
      // Restaura o cursor ao estado padrão
      document.body.style.cursor = 'default'
    }
    try {
      appInsights &&
        appInsights.trackEvent({
          name: 'Documento reclassificado',
          properties: {
            newDocument: updatedDocument,
          },
        })
    } catch (error) {
      console.log(error)
      appInsights && appInsights.trackException({ error: new Error(error) })
    }
  }

  const handlePartForm = (tag) => {
    setPartFormListState(tag)
    // console.log(`partFormListState`, partFormListState)
  }

  // useEffect(() => {
  //   console.log(`USEEFFECT partFormListState:`, partFormListState)
  // }, [partFormListState])

  // useEffect(() => {
  //   console.log(`USEEFFECT activetag:`, activeTag)
  // }, [partFormListState])

  const handleClassificationDocs = (
    option,
    itemKeySelect,
    idSelected,
    value
  ) => {
    const findIndexMainKey = submitDocList.find(
      (item) => item.key === itemKeySelect
    )
    // console.log('Item encontrado para itemKeySelect:', findIndexMainKey)

    if (findIndexMainKey) {
      setSubmitDocList((prevList) => {
        // console.log('Atualizando submitDocList, lista anterior:', prevList)
        const updatedList = prevList.map((prev) => {
          if (prev.key === itemKeySelect) {
            const existingItemIndex = prev.item.findIndex(
              (item) => item.id === idSelected
            )

            if (existingItemIndex !== -1) {
              const updatedItems = prev.item.map((item, index) => {
                if (index === existingItemIndex) {
                  return {
                    ...item,
                    id: idSelected,
                    keyJson: option.key,
                    labelJson: option.label,
                    valueDoc: value,
                  }
                }
                return item
              })

              return {
                key: prev.key,
                item: updatedItems,
              }
            } else {
              return {
                key: prev.key,
                item: [
                  ...(Array.isArray(prev.item) ? prev.item : []),
                  {
                    id: idSelected,
                    keyJson: option.key,
                    labelJson: option.label,
                    valueDoc: value,
                  },
                ],
              }
            }
          }
          return prev
        })

        return updatedList
      })
    } else {
      const keyDocSubmit = {
        key: itemKeySelect,
        item: [
          {
            id: idSelected,
            keyJson: option.key,
            labelJson: option.label,
            valueDoc: value,
          },
        ],
      }
      //  console.log('Novo item criado para submitDocList:', keyDocSubmit)

      setSubmitDocList((prevList) => [...prevList, keyDocSubmit])
    }
  }

  useEffect(() => {
    //  console.log('submitDocList atualizado:', submitDocList)
  }, [submitDocList])

  const handleValidateDocument = () => {}

  useEffect(() => {
    const totalValidated = configData?.documents?.reduce((acc, currentItem) => {
      if (currentItem?.validated) {
        return acc + 1
      }
      return acc
    }, 0)

    setTotalValidateDocs(totalValidated)
    // console.log(totalValidated)
  }, [configData])

  const handleAllDocsValidatesValue = (value) => {
    value === true ? setAllDocsValidated(true) : setAllDocsValidated(false)
  }

  const handleContractParts = (part) => {
    //  console.log('handleContractParts chamado com:', part)
    if (contractPartsSelected.length >= 1) {
      const hasSameIdDocument = contractPartsSelected?.map((group) => {
        const filteredItems = group.items?.filter(
          (item) => item.idDocument !== part.idDocument
        )
        return { ...group, items: filteredItems }
      })

      setContractPartsSelected(hasSameIdDocument)
    }

    setContractPartsSelected((prev) => {
      const existingPart = prev.find((item) => item.name === part.name)
      if (existingPart) {
        const updatedParts = prev.map((item) =>
          item.name === part.name
            ? { ...item, items: [...item.items, part] }
            : item
        )
        return updatedParts
      }
      return [
        ...prev,
        { id: part.id, name: part.name, color: part.color, items: [part] },
      ]
    })
  }

  const handleAddFilter = (filter) => {}

  useEffect(() => {
    const filteredArray = contractPartsSelected.filter(
      (part) => part.items.length >= 1
    )
    if (filteredArray) {
      setFilteredPartsContract(filteredArray)
    } else {
    }
  }, [contractPartsSelected])

  const handleFilter = async (tag) => {
    if (!tag || !tag.documentId || tag.documentId.length <= 0) {
      handleClearFilter()
      setFilterselectedcolor()
      return
    }
    // console.log("por um acaso chamou aqui") //nao chama aqui
    // Verifica se 'tag' existe antes de continuar
    if (tag) {
      const updatedCollection = tag.documentId
      setCollection(updatedCollection)
      setFilterselectedcolor(tag)
      // console.log('talvez aqui?')
      setIdFileDoc(tag.documentId[0])
    } else {
      setFilterselectedcolor()
    }
  }

  const updatedFilterSelected = (tag) => {
    // console.log("batendooooooo")
    // console.log('updatedFilterSelected chamado com tag:', tag)

    let selectedTag

    if (filterSelectedColor?.part === 'Pendentes') {
      selectedTag = tag.find((item) => item.part === 'Pendentes')
      // console.log(
      //   'Filtro aplicado: Pendentes. selectedTag encontrado:',
      //   selectedTag
      // )
    } else if (filterSelectedColor?.part === 'Validados') {
      selectedTag = tag.find((item) => item.part === 'Validados')
      // console.log(
      //   'Filtro aplicado: Validados. selectedTag encontrado:',
      //   selectedTag
      // )
    } else if (filterSelectedColor?.part === 'Não Cadastrados') {
      selectedTag = tag.find((item) => item.part === 'Não Cadastrados')
      // console.log(
      //   'Filtro aplicado: Não Cadastrados. selectedTag encontrado:',
      //   selectedTag
      // )
    }

    if (selectedTag && selectedTag.documentId.length === 0) {
      console.warn('selectedTag encontrado, mas documentId está vazio.')
      handleClearFilter()
      return
    }

    if (selectedTag) {
      // console.log('selectedTag válido encontrado:', selectedTag)
      // setCollection()
      const updatedCollection = selectedTag?.documentId
      // console.log('Atualizando collection com:', updatedCollection)
      setCollection(updatedCollection)
      setFilterselectedcolor(selectedTag)
      // console.log('oi gente')
      setIdFileDoc(selectedTag?.documentId[0])
      // console.log('IdFileDoc atualizado para:', selectedTag?.documentId[0])
    } else {
      // console.log('Nenhum selectedTag encontrado. Limpando filtros.')
      setFilterselectedcolor()
      handleClearFilter()
    }
  }

  const handleClearFilter = () => {
    //  console.log('handleClearFilter chamado')
    const idCollectionInitial = configData?.documents.map(
      (item) => item.documentId
    )
    setCollection(idCollectionInitial)
    setFilterselectedcolor()
  }

  const handleAnalyzerData = (update) => {
    // console.log('Dados recebidos para atualizar em handleAnalyzerData:', update)

    if (!update || (Array.isArray(update) && update.length === 0)) {
      console.warn(
        'Aviso: Os dados fornecidos para atualização estão vazios ou indefinidos.'
      )
    }

    setAnalyzerDataList(update)
    // console.log('Estado de analyzerDataList atualizado com sucesso.')
  }

  const handleConfigData = (updatedDocuments) => {
    // console.log(
    //   'handleConfigData chamado com updatedDocuments:',
    //   updatedDocuments
    // )

    if (
      !updatedDocuments ||
      (Array.isArray(updatedDocuments) && updatedDocuments.length === 0)
    ) {
      console.warn(
        'Aviso: Os documentos fornecidos para atualização estão vazios ou indefinidos.'
      )
    }

    setConfigData((prevConfigData) => {
      // console.log('Estado anterior de configData:', prevConfigData)
      const newConfigData = {
        ...prevConfigData,
        documents: updatedDocuments,
      }
      // console.log('Novo estado de configData após atualização:', newConfigData)
      return newConfigData
    })

    // console.log('Estado de configData atualizado com sucesso.')
  }

  const sendNewDocument = async (document) => {
    // console.log(
    //   'Iniciando envio de novo documento com o seguinte payload:',
    //   document
    // )
    setLoadingIdButton((prevState) => ({
      ...prevState,
      [id]: 'loading',
    }))
    console.log('document', document)
    const id = `${document?.part || ''}-${document?.numberTagShowForm || 1}`

    if (!document || typeof document !== 'object') {
      console.warn('Aviso: O documento fornecido é inválido ou está vazio.')
      return null // Ou você pode tratar com outra lógica de fallback ou erro
    }

    const payload = {
      idParams: configId,
      document: document,
    }

    // console.log('Payload que será enviado na requisição:', payload)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    // console.log('Opções da requisição:', requestOptions)

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/validateDocument`,
        requestOptions
      )

      // console.log('Resposta da requisição recebida:', response)
      if (!response.ok) {
        setLoadingIdButton((prevState) => ({
          ...prevState,
          [id]: false,
        }))
        console.error(
          'Erro na resposta da requisição. Status:',
          response.status,
          'Status Text:',
          response.statusText
        )
      }
      setLoadingIdButton((prevState) => ({
        ...prevState,
        [id]: false,
      }))
      return response
    } catch (error) {
      setLoadingIdButton((prevState) => ({
        ...prevState,
        [id]: false,
      }))
      console.error('Erro ao tentar enviar o documento:', error)
      throw error // Repassa o erro para quem chama a função
    }
  }

  const handleOpenModalAllLists = () => {
    setOpenModalAllLists(!openModalAllLists)
  }

  const handlePreviewDocsReview = () => {
    setOpenDocumentPreview(!openDocumentPreview)
  }

  const filterDocumentsByCollection = (configData, collection) => {
    return configData?.documents?.filter((item) =>
      collection.includes(item.documentId)
    )
  }

  const deleteDocumentFromConfig = (configData, idFileDoc) => {
    return configData?.documents?.filter((doc) => doc.documentId !== idFileDoc)
  }

  const updateDocumentListData = (documentListData, idFileDoc) => {
    return documentListData?.filter((doc) => idFileDoc !== doc.documentId)
  }

  const handleCollection = async () => {
    // console.log('Iniciando handleCollection')
    // console.log('ConfigData:', configData)
    // console.log('Collection:', collection)
    // console.log('filterselectedcolor:', filterSelectedColor)

    const configDataFilter = filterDocumentsByCollection(configData, collection)
    // console.log('ConfigDataFilter:', configDataFilter)

    if (configDataFilter) {
      // console.log('ID do documento a ser removido:', idFileDoc)

      if (!configData || !configData.documents) {
        console.error('ConfigData ou configData.documents está indefinido.')
        return toast.error('Erro ao processar os dados, tente novamente!', {
          theme: 'light',
        })
      }

      const documentToDelete = configData.documents.find((item) => {
        // console.log('documentToDelete: Verificando item:', item)
        return item.documentId === idFileDoc
      })

      if (!documentToDelete) {
        console.error('Documento não encontrado com o ID:', idFileDoc)
        return toast.error('Documento não encontrado!', {
          theme: 'light',
        })
      }

      // console.log('Documento a ser removido:', documentToDelete)

      const payload = {
        idParams: configId,
        document: documentToDelete,
      }

      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      }

      document.body.style.cursor = 'wait'

      try {
        const response = await fetchWithAuth(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/removeDocument`,
          requestOptions
        )

        if (!response.ok) {
          throw new Error('Erro ao tentar remover o documento no servidor.')
        }

        // console.log('Requisição bem-sucedida:', response)

        const updatedDocuments = deleteDocumentFromConfig(configData, idFileDoc)
        // console.log('Documentos atualizados:', updatedDocuments)

        setConfigData((prevConfigData) => ({
          ...prevConfigData,
          documents: updatedDocuments,
        }))

        const updatedDocumentListData = updateDocumentListData(
          documentListData,
          idFileDoc
        )
        // console.log('Lista de documentos atualizada:', updatedDocumentListData)
        setDocumentListData(updatedDocumentListData)

        const updatedCollectionTest = updatedDocuments.filter(
          (doc) => doc.validated === documentToDelete.part
        )

        // console.log('updatedCollectionTest', updatedCollectionTest)

        if (documentToDelete) {
          if (filterSelectedColor) {
            filterSelectedColor.documentId =
              filterSelectedColor.documentId.filter(
                (id) => id !== documentToDelete.documentId
              )

            setCollection(filterSelectedColor.documentId)
            setIdFileDoc(filterSelectedColor.documentId[0])

            // console.log(
            //   filterSelectedColor,
            //   filterSelectedColor.documentId.length
            // )

            if (filterSelectedColor.documentId.length === 0) {
              handleClearFilter()
              const updatedCollection = updatedDocuments.map(
                (doc) => doc.documentId
              )
              setCollection(updatedCollection)
              setIdFileDoc(updatedCollection[0])
            }
          } else {
            const updatedCollection = updatedDocuments.map(
              (doc) => doc.documentId
            )
            setCollection(updatedCollection)
            setIdFileDoc(updatedCollection[0])
            // console.log('documento resolvido')
          }
          toast.success(`Documento removido com sucesso!`, {
            theme: 'light',
          })
        }
      } catch (error) {
        console.error('Erro na requisição:', error)
        return toast.error(`Erro ao deletar o documento, tente novamente!`, {
          theme: 'light',
        })
      } finally {
        document.body.style.cursor = 'default'
      }
    }
  }

  const handleClassification = (docValidatedData) => {
    try {
      let localCount = 0 // Variável local para contar os documentos

      const newDocListData = configData?.documents?.map((doc) => {
        if (doc.documentId === docValidatedData.documentId) {
          return docValidatedData
        } else {
          return doc
        }
      })

      // console.log('newDocListData declarado:', newDocListData)
      const analyzerDataValidatedDocument = analyzerDataList.find(
        (data) => data?.retorno?.idDocBox === docValidatedData?.documentId
      )
      // console.log(
      //   'analyzerDataValidatedDocument declarado:',
      //   analyzerDataValidatedDocument
      // )

      newDocListData.forEach((document, index) => {
        try {
          // console.log(`Passei no loop ${index} vezes`)
          const analyzerData = analyzerDataList.find(
            (data) => data?.retorno?.idDocBox === document?.documentId
          )
          // console.log('analyzerData declarado:', analyzerData)
          if (!document.validated && !document.part) {
            if (analyzerData) {
              const docValidated = docValidatedData

              if (docValidated?.classification?.titulo) {
                // console.log('docValidated:', docValidated)
                const tituloKey = docValidated.classification.titulo
                const tituloValue =
                  analyzerDataValidatedDocument?.retorno?.dados[
                    tituloKey
                  ]?.toLowerCase() || null
                const ocrContent =
                  analyzerData?.retorno?.metaDados?.ocrContent?.toLowerCase() ||
                  ''

                if (tituloValue && ocrContent) {
                  const tituloValueNormalized = tituloValue.toLowerCase().trim()
                  const ocrContentNormalized = ocrContent.toLowerCase().trim()

                  const removeSpecialCharacters = (str) =>
                    str.replace(/[^\w\s]/gi, '')

                  const tituloValueClean = removeSpecialCharacters(
                    tituloValueNormalized
                  )
                  const ocrContentClean =
                    removeSpecialCharacters(ocrContentNormalized)

                  const allWordsMatch =
                    ocrContentClean.includes(tituloValueClean)

                  if (allWordsMatch) {
                    const updatedDocument = {
                      ...document,
                      part: docValidatedData?.part || null,
                      numberTag: docValidatedData?.numberTag || null,
                    }

                    Object.assign(updatedDocument, { suggested: true })

                    // Incrementa a variável local
                    localCount += 1

                    // console.log(
                    //   'Object.assign funcionou?',
                    //   updatedDocument?.suggested
                    // )

                    // console.log('Saída de updatedDocument', updatedDocument)
                    const array = (newDocListData[index] = updatedDocument)
                    // console.log('Saída do Array de newDocListData', array)
                  }
                }
              }
            }
          }
        } catch (error) {
          console.error(`Erro no loop na iteração ${index}:`, error)
        }
      })

      // Usa localCount para verificar se deve exibir o toast
      if (localCount > 0) {
        const message =
          localCount === 1
            ? `Identificamos e classificamos 1 documento que parece estar relacionado à mesma parte!`
            : `Identificamos e classificamos ${localCount} documentos que parecem estar relacionados à mesma parte!`

        toast.info(message, { theme: 'light' })
      }
      // console.log("localCount", localCount)
      // console.log('Saída de newDocListData', newDocListData)
      setDocumentListData(newDocListData)
      handleConfigData(newDocListData)
      // console.log('Saída de configData', configData)
    } catch (error) {
      console.error('Erro em handleClassification:', error)
    }
  }

  const handleDataDocumentToLint = (data) => {
    // console.log('quem é data', data)
    setDataDocumentToLint((prev) => {
      const hasData = prev.findIndex(
        (item) => item.part === data.part && item.numberTag === data.numberTag
      )

      return hasData !== -1
        ? prev.map((item, index) => (index === hasData ? data : item))
        : [...prev, data]
    })
  }

  const updatedDataDocumentToLint = (name, value) => {
    setDataDocumentToLint((prevData) => {
      return prevData.map((item) => {
        if (
          item.part === activeTag.part &&
          item.numberTag === activeTag.numberTagShowForm
        ) {
          return {
            ...item,
            data: {
              ...item.data,
              [name]: value,
            },
          }
        }
        return item
      })
    })
  }

  const handlePartFormUpdate = async (tag) => {
    // console.log("Tag recebida:", tag);

    const id = `${tag.part}-${tag.numberTagShowForm}`
    // console.log("ID gerado:", id);

    setLoadingIdButton((prevState) => ({
      ...prevState,
      [`${tag.part}-${tag.numberTagShowForm}`]: 'loading',
    }))
    // console.log("Loading atualizado para 'loading' no botão:", id);

    if (!tag) {
      console.error('Erro: tag não fornecida ou inválida.')
      setLoadingIdButton((prevState) => ({
        ...prevState,
        [`${tag.part}-${tag.numberTagShowForm}`]: false,
      }))
      return
    }

    if (isFormDataEmpty) {
      if (showForm) {
        // Ignora validação se a parte for "Doc. Negociação" ou não estiver definida
        if (activeTag?.part === 'Doc. Negociação' || !activeTag?.part) {
          return
        }

        // Ignora validação se a parte ativa for igual à `labelTag?.part`
        if (activeTag?.part === labelTag?.part) {
          return
        }
      }

      // Exibe erro se o formulário está vazio e não atende às condições acima
      console.error('Erro: Formulário vazio.')
      toast.error(
        'Por favor, revise e preencha todos os campos antes de validar.',
        { theme: 'light' }
      )
      setLoadingIdButton((prevState) => ({
        ...prevState,
        [`${tag.part}-${tag.numberTagShowForm}`]: false,
      }))
      return
    }

    console.log('Tag válida:', tag)
    //
    const updatedForm = partFormListState?.map((item) => {
      const isMatchingPart = item?.part === tag.part
      // console.log(
      //   `Atualizando item no partFormListState - Part: ${item?.part}, Matching: ${isMatchingPart}`
      // );

      return isMatchingPart
        ? {
            ...item,
            tagsValidated: item.tagsValidated.map((tagItem) => {
              const isMatchingTag = tagItem.numberTag === tag?.numberTagShowForm
              // console.log(
              //   `Atualizando tagValidated - NumberTag: ${tagItem.numberTag}, Matching: ${isMatchingTag}`
              // );

              return isMatchingTag
                ? { ...tagItem, tagValidated: !tagItem.tagValidated }
                : tagItem
            }),
            numberTagShowForm: tag?.numberTagShowForm,
          }
        : item
    })

    // console.log("Formulário atualizado (updatedForm):", updatedForm);

    const updatedActiveTag = updatedForm?.find(
      (item) =>
        item.part === tag.part &&
        item.numberTagShowForm === tag?.numberTagShowForm
    )

    // console.log("Tag ativa atualizada (updatedActiveTag):", updatedActiveTag);

    let itensForTag = resumeTags?.find(
      (item) => item.tag === updatedActiveTag?.part
    )

    // console.log("Itens para a tag (itensForTag):", itensForTag);
    // console.log("resumetags:", resumeTags);

    if (itensForTag) {
      itensForTag.tagsValidated = updatedActiveTag?.tagsValidated || []
      // console.log("Tags validadas atualizadas em itensForTag:", itensForTag.tagsValidated);
    }

    // console.log("Iterando sobre dataDocumentToLint...", dataDocumentToLint);
    dataDocumentToLint.forEach(({ numberTag, data, part }) => {
      // console.log("Número da tag:", numberTag, "Data associada:", data, "Part:", part);
      Object.entries(data).forEach(([key, value]) => {
        // console.log("Procurando chave no itensForTag:", key);
        const item = itensForTag?.itens?.find((i) => i.key === key)

        if (item) {
          // console.log("Chave encontrada:", key, "Atualizando valores...");
          item.values = item.values || []
          // console.log("item:", item)
          if (itensForTag.tag === part) {
            const existingData = item.values.find(
              (v) => Number(v.numberTag) === Number(numberTag)
            )
            // console.log("Dados existentes:", existingData);
            if (existingData) {
              // console.log("Atualizando valor existente:", existingData);
              existingData.value = value
            } else {
              // console.log("Adicionando novo valor:", { numberTag, value });
              item.values.push({ numberTag, value })
            }
          }
        } else {
          // console.log("Chave não encontrada:", key);
        }
      })
    })

    // console.log("Itens para a tag após atualização (itensForTag):", itensForTag);

    const payload = {
      idParams: configId,
      tagName: itensForTag?.tag,
      newData: itensForTag,
    }

    // console.log("Payload para envio:", payload);

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/setDataTags`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      )

      // console.log("Resposta da API:", response);

      if (!response.ok) {
        console.error(
          'Erro na resposta da API:',
          response.status,
          response.statusText
        )
        toast.error('Os dados não foram validados, tente novamente!', {
          theme: 'light',
        })
        setLoadingIdButton((prevState) => ({
          ...prevState,
          [id]: false,
        }))
        return
      }

      const isValidated = updatedActiveTag?.tagsValidated.some(
        (tag) => tag.tagValidated
      )
      // console.log("Validação da tag concluída:", isValidated);

      toast[isValidated ? 'success' : 'info'](
        isValidated ? 'Dados validados com sucesso!' : 'Validação removida.',
        {
          theme: 'light',
          progressStyle: isValidated
            ? { backgroundColor: '#9ca624' }
            : undefined,
          style: { background: '#f4f4f4' },
        }
      )

      handleAllDataValidated(updatedActiveTag)
      setActiveTag(updatedActiveTag)
      setPartFormListState(updatedForm)
      setLoadingIdButton((prevState) => ({
        ...prevState,
        [id]: false,
      }))
      itensForTag = []
    } catch (error) {
      console.error('Erro ao enviar o documento:', error)
      toast.error('Erro ao validar os dados. Tente novamente.', {
        theme: 'light',
      })
      setLoadingIdButton((prevState) => ({
        ...prevState,
        [id]: false,
      }))
      itensForTag = []
    }
  }

  useEffect(() => {
    // console.log('dataDocumentToLint', dataDocumentToLint)
    // console.log('resumeTags', resumeTags)
  }, [])

  const handleAllDataValidated = (data) => {
    if (dataDocumentToLint) {
      const isMatchingData =
        dataDocumentToLint?.part === data?.part &&
        dataDocumentToLint?.numberTag === data?.numberTagShowForm

      setAllDataValidated((prevData) => {
        const hasTag = prevData.some(
          (item) =>
            item.part === data.part && item.numberTag === data.numberTagShowForm
        )

        return hasTag
          ? prevData.map((item) =>
              item.part === data.part &&
              item.numberTag === data.numberTagShowForm
                ? {
                    ...item,
                    tagsValidated: item.tagsValidated.map((tagItem) =>
                      tagItem.numberTag === data.numberTagShowForm
                        ? { ...tagItem, tagValidated: !tagItem.tagValidated }
                        : tagItem
                    ),
                    dataIa: isMatchingData
                      ? dataDocumentToLint.data
                      : item.dataIa,
                  }
                : item
            )
          : [
              ...prevData,
              {
                ...data,
                tagsValidated: [
                  ...(data?.tagsValidated || []),
                  {
                    numberTag: data?.numberTagShowForm,
                    tagValidated: true,
                  },
                ],
                dataIa: isMatchingData ? dataDocumentToLint?.data : null,
              },
            ]
      })
    }
  }

  useEffect(() => {
    // console.log('combinedDocuments:', combinededDocuments)
    // console.log('dataDocumentToLint:', dataDocumentToLint)
    // console.log('resumeTags:', resumeTags)
  }, [])

  const handleCopyToClipboard = (formData) => {
    // console.log({ formData })
    if (!formData.data || Object.keys(formData.data).length === 0) {
      return toast.error(`Por favor, preencha os dados antes de continuar!`, {
        theme: 'light',
        progressStyle: { backgroundColor: '#a6151c' },
        style: {
          background: '#f4f4f4',
        },
      })
    }

    const formFields = resumeTags?.find((item) => item.tag === activeTag?.part)

    // console.log('formFields', formFields)

    const formDataText = formFields?.itens
      ?.map((doc) => `${doc.label}: ${formData.data[doc.key] || ''}`)
      .join('\n')

    // console.log('transferencia:', formDataText)

    navigator.clipboard
      .writeText(formDataText)
      .then(() =>
        toast.success(`Dados copiados para a área de transferência!`, {
          theme: 'light',
          progressStyle: { backgroundColor: '#9ca624' },
          style: {
            background: '#f4f4f4',
          },
        })
      )
      .catch((err) =>
        console.error('Erro ao copiar para a área de transferência: ', err)
      )
  }

  const handleCheckFormEmpty = (checkFormEmpty) => {
    setIsFormDataEmpty(checkFormEmpty)
  }

  useEffect(() => {
    // console.log('allDataValidated', allDataValidated)
    // console.log('quando muda o dataDocumentToLint', dataDocumentToLint)
  }, [dataDocumentToLint])

  const handleLoadingForm = (value) => {
    // console.log('ta batendo aqui?', value)
    setIsLoadingForm(value)
  }

  // useEffect(() => {
  //   if (configData?.documents && showForm) {
  //     setIsLoadingForm(true)
  //     const finalDocumentsArray = groupDocuments(
  //       configData?.documents,
  //       tagsWithColors
  //     )

  //     const groupedDocuments = finalDocumentsArray.reduce((acc, doc) => {
  //       const key = `${doc.part}-${doc.numberTag}`
  //       if (!acc[key]) {
  //         acc[key] = {
  //           id: Object.keys(acc)?.length + 1,
  //           part: doc.part,
  //           numberTag: doc.numberTag,
  //           documents: [],
  //           tagAdd: doc.tagAdd,
  //         }
  //       }

  //       doc.documentId?.forEach((id) => {
  //         const docConfigData = configData.documents?.find(
  //           (item) => item.documentId === id
  //         )
  //         const docAnalyzerData = analyzerDataList?.find(
  //           (item) => item?.retorno && item.retorno.idDocBox === id
  //         )

  //         if (docConfigData || docAnalyzerData) {
  //           acc[key].documents.push({
  //             documentId: id,
  //             documentData: {
  //               configData: docConfigData || {},
  //               analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //             },
  //           })
  //         }
  //       })

  //       return acc
  //     }, {})

  //     const otherDocuments = configData?.documents?.reduce((acc, doc) => {
  //       // console.log('chega a bater aqui?')
  //       //   console.log('entra aqui?')
  //       if (analyzerDataList) {
  //         const docAnalyzerData = analyzerDataList?.find(
  //           (item) => item?.retorno && item.retorno.idDocBox === doc.documentId
  //         )
  //         // console.log({ docAnalyzerData })
  //         acc.push({
  //           documentId: doc.documentId,
  //           documentData: {
  //             configData: doc || {},
  //             analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //           },
  //         })
  //       }

  //       return acc
  //     }, [])

  //     if (otherDocuments?.length > 0) {
  //       groupedDocuments['Outros'] = {
  //         id: Object.keys(groupedDocuments)?.length + 1,
  //         part: 'Outros',
  //         numberTag: null,
  //         documents: otherDocuments,
  //       }
  //     }

  //     const finalArray = Object.values(groupedDocuments)

  //     if (finalArray.length > 0) {
  //       const newCombinededDocuments = finalArray.map((doc) => {
  //         const dadosList =
  //           doc.documents?.flatMap((item) => {
  //             const dados = item?.documentData?.analyzerData?.dados || {}
  //             const label =
  //               item?.documentData?.configData?.classification?.label

  //             return Object.entries(dados).map(([key, value]) => ({
  //               key,
  //               label: label ? label : 'Não classificado',
  //               value: value ? value : 'Não cadastrado',
  //             }))
  //           }) || []

  //         const dadosSeparados = dadosList
  //           .filter(({ key }) => !/hidden/i.test(key))
  //           .map(({ key, value, label }) => ({
  //             key,
  //             label,
  //             value,
  //           }))

  //         return {
  //           part: doc?.part,
  //           numberTag: doc?.numberTag,
  //           dados: dadosSeparados,
  //         }
  //       })

  //       console.log(
  //         'quem tem dentro de newCombinededDocuments',
  //         newCombinededDocuments
  //       )

  //       if (newCombinededDocuments.length > 0) {
  //         setCombinededDocuments(newCombinededDocuments)
  //         if (newCombinededDocuments.length > 0) {
  //           setTimeout(() => {
  //             setIsLoadingForm(false)
  //           }, 3000)
  //         }
  //         console.log({ finalArray })
  //       }
  //     }

  //     // const processDocuments = (configData, tagsWithColors) => {
  //     //   if (configData?.documents) {
  //     //     // Agrupar documentos
  //     //     const groupedDocuments = configData.documents.reduce((acc, doc) => {
  //     //       const key = `${doc.part}-${doc.numberTag}`

  //     //       if (!acc[key]) {
  //     //         acc[key] = {
  //     //           id: Object.keys(acc).length + 1,
  //     //           part: doc.part,
  //     //           numberTag: doc.numberTag,
  //     //           documents: [],
  //     //           tagAdd: doc.tagAdd,
  //     //         }
  //     //       }

  //     //       doc.documentId?.forEach((id) => {
  //     //         const docConfigData = configData.documents.find(
  //     //           (item) => item.documentId === id
  //     //         )
  //     //         const docAnalyzerData = configData.documents.find(
  //     //           (item) => item?.retorno && item.retorno.idDocBox === id
  //     //         )

  //     //         if (docConfigData || docAnalyzerData) {
  //     //           acc[key].documents.push({
  //     //             documentId: id,
  //     //             documentData: {
  //     //               configData: docConfigData || {},
  //     //               analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //     //             },
  //     //           })
  //     //         }
  //     //       })

  //     //       return acc
  //     //     }, {})

  //     //     // Obter documentos "Outros"
  //     //     const otherDocuments = configData.documents.reduce((acc, doc) => {
  //     //       if (!doc.part && !doc.numberTag) {
  //     //         const docAnalyzerData = configData.documents.find(
  //     //           (item) =>
  //     //             item?.retorno && item.retorno.idDocBox === doc.documentId
  //     //         )
  //     //         acc.push({
  //     //           documentId: doc.documentId,
  //     //           documentData: {
  //     //             configData: doc || {},
  //     //             analyzerData: docAnalyzerData ? docAnalyzerData.retorno : {},
  //     //           },
  //     //         })
  //     //       }
  //     //       return acc
  //     //     }, [])

  //     //     const finalArray = Object.values(groupedDocuments)

  //     //     if (otherDocuments.length > 0) {
  //     //       finalArray.push({
  //     //         id: Object.keys(groupedDocuments).length + 1,
  //     //         part: 'Outros',
  //     //         numberTag: null,
  //     //         documents: otherDocuments,
  //     //       })
  //     //     }

  //     //     // Combinar dados dos documentos
  //     //     const newCombinedDocuments = finalArray.map((doc) => {
  //     //       const dadosList =
  //     //         doc.documents?.flatMap((item) => {
  //     //           const dados = item?.documentData?.analyzerData?.dados || {}
  //     //           const label =
  //     //             item?.documentData?.configData?.classification?.label ||
  //     //             'Não classificado'

  //     //           return Object.entries(dados).map(([key, value]) => ({
  //     //             key,
  //     //             label,
  //     //             value: value || 'Não cadastrado',
  //     //           }))
  //     //         }) || []

  //     //       const dadosSeparados = dadosList
  //     //         .filter(({ key }) => !/hidden/i.test(key))
  //     //         .map(({ key, value, label }) => ({ key, label, value }))

  //     //       return {
  //     //         part: doc.part,
  //     //         numberTag: doc.numberTag,
  //     //         dados: dadosSeparados,
  //     //       }
  //     //     })

  //     //     // Atualiza o estado apenas se necessário

  //     //     setCombinededDocuments(newCombinedDocuments)
  //     //     handleLoadingForm(false)
  //     //   }
  //     // }
  //   }

  //   // Chame esta função passando configData e tagsWithColors
  //   // Exemplo: processDocuments(configData, tagsWithColors);
  // }, [configData, tagsWithColors]) // Adicione aqui as dependências necessárias

  /***** IA */

  const handleFormDataToAi = async () => {
    // console.log("tamo aqui?", activeTag?.tagsValidated?.some(
    //   (tag) => tag.numberTag === activeTag.numberTagShowForm && tag.tagValidated
    // ))
    // console.log("activeTag", activeTag)
    const id = `${activeTag.part}-${activeTag.numberTagShowForm}`

    if (
      activeTag?.tagsValidated?.some(
        (tag) =>
          tag.numberTagShowForm === activeTag.numberTagShowForm &&
          tag.tagValidated
      )
    )
      return
    // console.log("e aqui?")

    setLoadingId((prevState) => ({
      ...prevState,
      [id]: 'loading',
    }))
    if (isLoadingForm) return
    setIsLoadingForm(true)

    const listField = resumeTags.find((tag) => tag.tag === activeTag.part)
    const templateField = listField?.itens
      .filter((item) => item.key && item.description)
      .map((item) => `'${item.key}' ${item.description}`)
      .join(', ')

    console.log({ templateField })

    const hasDataIa = combinededDocuments.filter((doc) => {
      const activeNumberTag = showForm
        ? activeTag.numberTagShowForm
        : activeTag.numberTag
      return doc.part === activeTag.part && doc.numberTag === activeNumberTag
    })
    // console.log("activeTag", activeTag)
    // console.log("hasDataIa", hasDataIa)
    // console.log("documentLint", dataDocumentToLint)
    // console.log("combinededDocuments", combinededDocuments)

    if (hasDataIa.length > 0) {
      try {
        const results = await Promise.allSettled(
          hasDataIa.map((doc) => {
            const payload = {
              tipoPessoa: doc.part === 'Empresa' ? 'Jurídica' : 'Física',
              listaCampos: templateField,
              dadosJson: doc.dados.map((d) => `${d.key}: ${d.value}`).join(','),
              retornarGPTPrompt: false,
            }

            // console.log({ payload })

            const result = fetchWithAuth(
              `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CONTEXT_VIEW_ANALYZER}/unifyData`,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
              }
            ).then((response) => response.json())
            // console.log({ result })
            return result
          })
        )

        const combinedResult = results
          .filter(
            (result) =>
              result.status === 'fulfilled' && result.value.retorno?.dados
          )
          .reduce(
            (acc, result) => ({ ...acc, ...result.value.retorno.dados }),
            {}
          )

        Object.entries(combinedResult).forEach(([name, value]) => {
          updatedDataDocumentToLint(name, value)
        })

        // console.log('Resultados das requisições?????', results)
        // console.log('Dados combinados:', combinedResult)
      } catch (error) {
        console.error('Erro na classificação dos documentos:', error)
        toast.error('Erro na classificação dos documentos', { theme: 'light' })
      } finally {
        // console.log('Processamento finalizado')
        setIsLoadingForm(false)
        setLoadingId((prevState) => ({
          ...prevState,
          [id]: false,
        }))
      }
    }

    try {
      appInsights &&
        appInsights.trackEvent({
          name: 'Formulário preenchido com IA',
          properties: {
            documentId: idFileDoc,
          },
        })
    } catch (error) {
      appInsights && appInsights.trackException({ error: new Error(error) })
    }
  }

  useEffect(() => {
    // console.log('aqui é partFormListState', partFormListState)
    // console.log('combinededDocuments', combinededDocuments)
    // console.log('aqui é resumeTags', resumeTags)
  }, [combinededDocuments, resumeTags, partFormListState])
  return (
    <Provider
      value={{
        startingLoading,
        errorDocBox,
        isFormDataEmpty,
        isLoadingForm,
        handleLoadingForm,
        combinededDocuments,
        handleCheckFormEmpty,
        updatedDataDocumentToLint,
        handleCollection,
        handleCopyToClipboard,
        tagRequiredFile,
        labelTag,
        showLabelTag,
        canDownloaded,
        canPrinted,
        validatedDocumentsModalOpen,
        showLabelText,
        handleOpenModalValidatedDocuments,
        handleFormDataToAi,
        showClassification,
        validationDocument,
        showContractPart,
        handleClassification,
        handleDocumentsPartArray,
        handleDataDocumentToLint,
        dataDocumentToLint,
        previewBoxVisibility,
        documentsPartArray,
        handleAddFilter,
        boxToken,
        progressBar,
        tagsWithColors,
        setTagsWithColors,
        colorsByDocumentId,
        setDocumentColor,
        getDocumentColor,
        handleActiveTag,
        activeTag,
        previewSize,
        handleConfigData,
        handleSelectedDocument,
        configData,
        configId,
        docSelected,
        handleAnalyzerData,
        documentListData,
        contractPartsDoc,
        classificationListDocs,
        filterSelectedColor,
        handleClearFilter,
        handleAllDataValidated,
        allDataValidated,
        handleFilter,
        handleErrorDocBox,
        filteredPartsContract,
        handleContractParts,
        setContractPartsSelected,
        contractPartsSelected,
        originalConfigData,
        typeDocuments,
        handleTagSelected,
        partSelected,
        handleTypeDocumentsOption,
        openModalSendDocs,
        handleOpenModalSend,
        setAllDocsValidated,
        handleAllDocsValidatesValue,
        showDeleteButton,
        handleClassificationDocs,
        loadingDocumentsData,
        fileId,
        submitDocList,
        setSubmitDocList,
        updatedFilterSelected,
        collection,
        validatedDocument,
        showForm,
        setValidatedDocument,
        handleQttValidation,
        handlePartForm,
        handlePartFormUpdate,
        partFormListState,
        handleValidateDocument,
        setTotalValidateDocs,
        setIdFileDoc,
        totalValidateDocs,
        allDocsValidated,
        idFileDoc,
        analyzerDataList,
        confirmEditValuesDocLInt,
        setConfirmEditValuesDocLInt,
        setValidatedDocumentsModalOpen,
        setStartingLoading,
        showFilterButton,
        sendNewDocument,
        checkToken,
        handleUpdateActiveTag,
        openModalAllLists,
        showListDocuments,
        handleOpenModalAllLists,
        showError,
        loadingIdButton,
        loadingId,
        resumeTags,
        openDocumentPreview,
        showResumeDocToContract,
        showLabelCloseButton,
        handlePreviewDocsReview,
        setAnalyzerDataList,
        setDocumentListData,
        downloadDocument,
        disableValidationButton,
      }}
    >
      {props.children}
    </Provider>
  )
}
