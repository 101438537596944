import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import { DocsContext } from '../../context/docs-context'
import { ReactComponent as Copy } from '../../assets/image/copy-review.svg'
import FormComponents from '../form-components'
import DocumentSummary from '../document-summary'
import { getAppInsights } from '../../insights/app-insights'

const ListFormDocuments = () => {
  const {
    showForm,
    validationDocument,
    combinededDocuments,
    activeTag,
    labelTag,
    loadingId,
    documentsPartArray,
    handleCopyToClipboard,
  } = useContext(DocsContext)
  const [activeSection, setActiveSection] = useState('form')
  const [formData, setFormData] = useState({ data: {} })
  const [hasResumeTag, setHasResumeTag] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const appInsights = getAppInsights()

  useEffect(() => {
    // console.log("loadingId", loadingId)
    // console.log("combinededDocuments", combinededDocuments)
    // console.log("cade?", activeTag?.part, labelTag.part)
    // console.log("quem é isloading?", isLoading)
    setIsLoading(false)
    if(combinededDocuments) {
      setTimeout(() => {
        // setIsLoading(false)
      } , 5000)
    }
  })

  const handleSectionClick = (section) => {
    setActiveSection(section)
  }

  useEffect(() => {
    if (combinededDocuments && activeTag) {
      const showValidationData = combinededDocuments.filter(
        (item) =>
          item.part === activeTag.part &&
          item.numberTag === activeTag.numberTagShowForm
      )

      const dadosArray = showValidationData
        .map((item) => item.dados || [])
        .flat()

      if (dadosArray.length > 0) {
        // console.log('dadosArray', dadosArray)
        setHasResumeTag(dadosArray)
      } else {
        // console.log('foi')
        setHasResumeTag(false)
      }
    }
  }, [activeTag, combinededDocuments])

  const handleCopy = () => {
    handleCopyToClipboard(formData)
    try {
      appInsights &&
        appInsights.trackEvent({
          name: 'Dados copiados com sucesso',
        })
    } catch (error) {
      appInsights && appInsights.trackException({ error: new Error(error) })
    }
  }

  const updateFormData = (data) => {
    // console.log('data', data)
    setFormData(data)
  }

  useEffect(() => {
    // console.log(`ìs active no listform`, activeSection);
  }, [])

  // return isLoading && activeTag?.part === labelTag?.part ? (
  //   <h1>Loadingindis</h1>
  // ) : (

  return isLoading ? (
    <h1>Loadingindis</h1>
  ) : (
    <S.Section activeTag={activeTag} showForm={showForm} labelTag={labelTag}>
      {/* <S.Hr showForm={showForm} /> */}
      <S.WrapperSection>
        <S.Form
          activeTag={
            activeTag?.tagsValidated?.find(
              (tag) => tag.numberTag === activeTag?.numberTagShowForm
            )?.tagValidated
          }
          isActive={activeSection === 'form'}
          onClick={() => handleSectionClick('form')}
        >
          <S.Title>
            <h2>FORMULÁRIO</h2>
            <hr />
          </S.Title>
          <S.BtnCopy
            isActive={activeSection === 'form'}
            title={'Copiar os dados'}
            onClick={() => {
              if (activeSection === 'form') {
                handleCopyToClipboard(formData)
              }
            }}
          >
            <Copy />
          </S.BtnCopy>
        </S.Form>
        <S.DataTitle
          activeTag={
            activeTag?.tagsValidated?.find(
              (tag) => tag.numberTag === activeTag?.numberTagShowForm
            )?.tagValidated
          }
          isActive={activeSection === 'summary'}
          onClick={() => handleSectionClick('summary')}
        >
          <h2>DADOS EXTRAÍDOS</h2>
          <hr />
        </S.DataTitle>
      </S.WrapperSection>
      <S.Content>
        {activeSection === 'form' ? (
          <FormComponents onUpdateFormData={updateFormData} />
        ) : (
          <DocumentSummary />
        )}
      </S.Content>
    </S.Section>
  )
}

export default ListFormDocuments
