export const loadingReducer = (state, action) => {
    switch (action.type) {
      case 'LOAD_START':
        return {
          ...state,
          [action.key]: true,
        };
      case 'LOAD_END':
        return {
          ...state,
          [action.key]: false,
        };
      case 'INITIALIZE':
        if (state[action.key] === undefined) {
          return {
            ...state,
            [action.key]: false,
          };
        }
        return state;
      default:
        return state;
    }
  };